// @mui material components
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import uploadFile from "api/file/uploadFile";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import { useMaterialUIController } from "context";

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import config from "config/config";

const { imageRoot, baseUrl } = config;

function AddDocument() {
  const [controller /* , dispatch */] = useMaterialUIController();
  const [requests, setRequests] = useState([]);
  const [popup, setPopup] = useState(false);
  const [adding, setAdding] = useState(true);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [error, setError] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [status, setStatus] = useState("published");
  const [category, setCategory] = useState("");
  const [popupText, setPopupText] = useState("");
  const [isRequestReady, setIsRequestReady] = useState(false);
  const [requestSucceded, setRequestSucceded] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { user } = controller;

  const editor = useRef(null);

  function getInput(type, editFunction, defaultValue) {
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        className={error === type ? "field_with_error" : ""}
        value={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "30px" }}
      />
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        className="selectDefault"
        value={defaultValue}
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginBottom: "30px",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`option-role-${index.toString()}`}>
            {el}
          </option>
        ))}
      </select>
    );
  }

  function getCalendar(value, changeFunction, label) {
    return (
      <div
        className={error === label ? "field_with_error" : ""}
        style={{ position: "relative", marginRight: "10px", marginBottom: "30px" }}
      >
        <Datetime
          inputProps={{
            placeholder: label,
            value,
          }}
          closeOnSelect
          timeFormat={false}
          value={value}
          onChange={(e) => {
            changeFunction(moment(e).format("L"));
          }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  function getSwitch(value, changeFunction, label) {
    return (
      <div
        className="switch_container"
        style={{ position: "relative", marginRight: "10px", marginBottom: "30px" }}
      >
        {label}
        <Switch
          checked={value}
          onChange={() => {
            // CHANGE
            changeFunction(!value);
          }}
          sx={{
            width: 300,
            color: "success.main",
          }}
          color="warning"
          // disabled={this.state.isActive}
          value="onlyOwner"
        />
      </div>
    );
  }

  function saveEdit(file) {
    return new Promise((accept, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const self = this;
      reader.onloadend = function onloadend() {
        const base64data = reader.result;
        // console.log("IL BASE &$ è", {
        //   base64data,
        // });
        accept(base64data);
        /* return savePdf({
          file: base64data,
          restaurantId: self.props.restaurantId,
          index: self.state.index
        }).then(response => {
          console.log("LA risposta è ", { response });
          if (response.url && response.urlApi) {
            self.props.editRestaurant({
              [`pdf${self.state.index > 1 ? self.state.index : ""}`]: {
                urlRedirect: response.url,
                url: response.urlApi
              }
            });
          }
          // self.props.updateRestaurant({
          //   _id: self.props.restaurantId,
          //
          // });
        }); */
      };
    });
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <div className="popupInfoContainer">
          {adding ? (
            <div>
              <Card sx={{ padding: "30px", marginTop: "50px" }}>
                <div style={{ marginBottom: "20px" }}>Stai aggiungendo un file</div>
                <Grid container spacing={2} sx={{ marginBottom: "30px" }}>
                  <Grid item xs={12}>
                    {getInput("title", setTitle, title)}
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                    Descrizione: <br />
                    <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={(es) => {
                        setEditorState(es);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {getInput("tags", setTags, tags)}
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                    File: <br />
                    <MDDropzone
                      options={{
                        addRemoveLinks: true,
                        maxFilesize: 1000000,
                        // eslint-disable-next-line
                        url: (file) => {
                          return `${baseUrl}/v1/ping/pong`;
                        },
                        // eslint-disable-next-line
                        // complete: (file) => {
                        //   console.log("complete", file);
                        //   // eslint-disable-next-line
                        //   if (file._removeLink) {
                        //     // eslint-disable-next-line
                        //     file._removeLink.innerHTML = this.options.dictRemoveFile;
                        //   }
                        //   if (file.previewElement) {
                        //     return file.previewElement.classList.add("dz-complete");
                        //   }
                        // },
                        // eslint-disable-next-line
                        success: async (file) => {
                          const data = await saveEdit(file);
                          const [dataApp, datas] = data.split(";", 2);
                          const [enc, base64] = datas.split(",", 2);
                          if (base64) {
                            // TODO gestire il multi caricamento contemporaneo o toglierlo
                            uploadFile(base64, "pdf").then((res) => {
                              // console.log("ho fatto cose", res);
                            });
                          }
                          return true;
                        },
                        // removedfile: (file) => {
                        //   console.log("ho cancellato", file);
                        //   if (file.previewElement != null && file.previewElement.parentNode != null) {
                        //     file.previewElement.parentNode.removeChild(file.previewElement);
                        //   }
                        //   // eslint-disable-next-line
                        //   return this._updateMaxFilesReachedClass();
                        // },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {getCalendar(dateFrom, setDateFrom, "dateFrom")}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {getCalendar(dateTo, setDateTo, "dateTo")}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {getSelect(
                      ["published", "unpublished", "trashed", "archived"],
                      setStatus,
                      status
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
                  <IconButton
                    onClick={() => {
                      // console.log("devo fare qualcosa con il file");
                    }}
                    className="save_button"
                    size="small"
                    disableRipple
                  >
                    Salva
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      // console.log("faccio annulla");
                    }}
                    size="small"
                    className="cancel_button"
                    disableRipple
                  >
                    Annulla
                  </IconButton>
                </Grid>
              </Card>
            </div>
          ) : (
            <IconButton
              onClick={() => {
                setAdding(true);
              }}
              size="small"
              disableRipple
            >
              Aggiungi <Icon fontSize="medium">add</Icon>
            </IconButton>
          )}
        </div>
      </DashboardLayout>
      {popupSuccess && (
        <SweetAlert
          style={{ display: "block" }}
          title={popupTitle}
          success={requestSucceded}
          error={!requestSucceded}
          onConfirm={() => {
            setPopupSuccess(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {popupText}
        </SweetAlert>
      )}
    </>
  );
}

export default AddDocument;
