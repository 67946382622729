import config from "config/config";

const { baseUrl } = config;

/* *********************
  email
  password
********************** */

export default function login(BODY) {
  const endpoint = `${baseUrl}/v1/auth/login`;
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${baseUrl}`,
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify(BODY),
  };
  // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      // console.log('login response', response);
      if (response.status === 403) {
        throw new Error("403 user role forbidden");
      }
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .then((responseJson) => {
      // console.log("login responseJson", responseJson);
      if (responseJson && responseJson.tokens && responseJson.tokens.access) {
        localStorage.setItem("refresh", JSON.stringify(responseJson.tokens.refresh));
        localStorage.setItem("access", JSON.stringify(responseJson.tokens.access));
        localStorage.setItem("userid", JSON.stringify(responseJson.user.id));

        return responseJson;
      }
      return responseJson;
    });
}
