import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController, setMiniSidenav, setUser } from "context";
import createUser from "api/users/createUser";
import randomPassword from "utils/randomPassword";
import SweetAlert from "react-bootstrap-sweetalert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Text from "components/custom/form/Text";
import inputs from "components/custom/form/inputs";

export default function AddUser() {
  const [controller] = useMaterialUIController();
  const [popup, setPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupText, setPopupText] = useState("");
  const [requestSucceded, setRequestSucceded] = useState(false);
  const [onConfirmPopup, setOnConfirmPopup] = useState(null);
  const navigate = useNavigate();
  const [createdUserId, setCreatedUserId] = useState("");
  const [submitDisable, setSubmitDisable] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  function onSubmit(data) {
    const user = Object.fromEntries(Object.entries(data).filter(([key, value]) => !!value));
    if (!user.role) {
      user.role = "consultant";
    }
    if (!user.password) {
      user.password = randomPassword();
    }
    setCreatedUserId("");
    setOnConfirmPopup(null);
    setSubmitDisable(true);
    createUser(user)
      .then((res) => {
        setPopup(true);
        setRequestSucceded(true);
        setPopupTitle("Account creato");
        setPopupText("L'utente è stato creato con successo");
        if (res?.data?.id) {
          setCreatedUserId(res.data.id);
        }
        setSubmitDisable(false);
      })
      .catch((err) => {
        const { code, message } = err.response.data;
        if (code === 406 && message === "Email already taken by admin") {
          setPopup(true);
          setRequestSucceded(false);
          setPopupTitle("Ops!");
          setPopupText(
            "Esiste già un amministratore con questa email associata. Contattare il reparto tecnico."
          );
        } else if (code === 400 && message === '"email" must be a valid email') {
          setPopup(true);
          setRequestSucceded(false);
          setPopupTitle("Ops!");
          setPopupText("L'indirizzo email non è stato inserito correttamente!");
        } else {
          setPopup(true);
          setRequestSucceded(false);
          setPopupTitle("Ops!");
          setPopupText("Qualcosa è andato storto, riprova tra qualche minuto!");
        }
        setSubmitDisable(false);
      });
  }

  return (
    <DashboardLayout>
      {popup && (
        <SweetAlert
          style={{ display: "block" }}
          title={popupTitle}
          success={requestSucceded}
          error={!requestSucceded}
          onConfirm={() => {
            setPopup(false);
            if (requestSucceded && createdUserId) {
              navigate(`/dashboards/profile/${createdUserId}`);
            }
            if (onConfirmPopup) {
              onConfirmPopup();
            }
          }}
          onCancel={() => {
            setPopup(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{ boxShadow: "none" }}
          cancelBtnText="Chiudi"
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{ boxShadow: "none" }}
          showCloseButton
          showCancel={!requestSucceded}
        >
          {popupText}
        </SweetAlert>
      )}
      <DashboardNavbar />
      <Card>
        <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "30px",
                alignItems: "center",
                width: "max(50%, 600px)",
              }}
              p={5}
            >
              <Text control={control} {...inputs.name} />
              <Text control={control} {...inputs.lastName} />
              <Text control={control} {...inputs.email} />
              <Text control={control} {...inputs.phone} />
              <Text control={control} {...inputs.city} />
              <MDButton disabled={submitDisable} type="submit" variant="gradient" color="info">
                AGGIUNGI
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
