import React, { useEffect, useMemo } from "react";
import { MapContainer, Marker, TileLayer, Tooltip, useMap } from "react-leaflet";
import { useNavigate } from "react-router-dom";

import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import iconRetinaUrlRed from "assets/images/leaflet-icons/pin_user_2.png";
import iconUrlRed from "assets/images/leaflet-icons/pin_user.png";
import iconRetinaUrlBlue from "assets/images/leaflet-icons/house_2.png";
import iconUrlBlue from "assets/images/leaflet-icons/house.png";
import shadowUrl from "assets/images/leaflet-icons/custom_shadow.png";

import "react-leaflet-markercluster/dist/styles.min.css";

const RED_ICON = L.icon({
  iconRetinaUrl: iconRetinaUrlRed,
  iconUrl: iconUrlRed,
  shadowUrl,
  iconSize: [22, 36],
  iconAnchor: [11, 36],
});

const BLUE_ICON = L.icon({
  iconRetinaUrl: iconRetinaUrlBlue,
  iconUrl: iconUrlBlue,
  shadowUrl,
  iconSize: [22, 36],
  iconAnchor: [11, 36],
});

export function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}
export function distance([lon1, lat1], [lon2, lat2]) {
  const R = 6371e3; // metres
  const l1 = degreesToRadians(lat1);
  const l2 = degreesToRadians(lat2);
  const deltaLat = degreesToRadians(lat2 - lat1);
  const deltaLon = degreesToRadians(lon2 - lon1);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(l1) * Math.cos(l2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
}

function rad2degr(rad) {
  return (rad * 180) / Math.PI;
}
function degr2rad(degr) {
  return (degr * Math.PI) / 180;
}

/**
 * @param latLngInDeg array of arrays with latitude and longtitude
 *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
 *   [longtitude2] ...]
 *
 * @return array with the center latitude longtitude pairs in
 *   degrees.
 */
function getLatLngCenter(latLngInDegr) {
  const LATIDX = 0;
  const LNGIDX = 1;
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < latLngInDegr.length; i++) {
    const lat = degr2rad(latLngInDegr[i][LATIDX]);
    const lng = degr2rad(latLngInDegr[i][LNGIDX]);
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng);
    sumY += Math.cos(lat) * Math.sin(lng);
    sumZ += Math.sin(lat);
  }

  const avgX = sumX / latLngInDegr.length;
  const avgY = sumY / latLngInDegr.length;
  const avgZ = sumZ / latLngInDegr.length;

  // convert average x, y, z coordinate to latitude and longtitude
  const lng = Math.atan2(avgY, avgX);
  const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  const lat = Math.atan2(avgZ, hyp);

  return [rad2degr(lat), rad2degr(lng)];
}

function Cluster({ files, users, center, filter = {} }) {
  const navigate = useNavigate();
  const map = useMap();

  function flyToBounds() {
    /* if (!filter || !filter.city) {
      console.log(filter);
      return;
    } */
    const { city, address } = filter;
    const fileLocations = [];
    const userLocations = [];
    if (files && files.length > 0) {
      files
        .filter((file) => !city || city.toLowerCase() === file?.city?.toLowerCase())
        .filter((file) => !address || address.toLowerCase() === file?.address?.toLowerCase())
        .forEach((file) => fileLocations.push([file.latitude, file.longitude]));
    }
    if (users && users.length > 0) {
      users
        .filter((user) => !city || city.toLowerCase() === user?.city?.toLowerCase())
        .filter((user) => !address || address.toLowerCase() === user?.address?.toLowerCase())
        .forEach((user) => userLocations.push([user.latitude, user.longitude]));
    }
    if (fileLocations.length > 0 || userLocations.length > 0) {
      map.flyToBounds([...fileLocations, ...userLocations]);
    }
  }

  useEffect(() => {
    map.flyTo(center, 5 /* map.getZoom() */);
  }, [center]);

  useEffect(() => {
    flyToBounds();
  }, [filter]);

  return (
    <>
      <MarkerClusterGroup showCoverageOnHover={false}>
        {files &&
          files.length > 0 &&
          files.map((file, index) => (
            <Marker
              icon={BLUE_ICON}
              // eslint-disable-next-line react/no-array-index-key
              key={`marker-openmap-file-${index}`}
              position={{ lat: file.latitude, lng: file.longitude }}
              eventHandlers={{
                click: () =>
                  window.open(`/dashboards/profile/${file.userId}`, "_blank", "noreferrer"),
              }}
            >
              <Tooltip direction="right" offset={[12, -24]} opacity={1}>
                <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                  {file.customerName && file.customerLastName && (
                    <MDTypography variant="subtitle2">
                      {file.customerName} {file.customerLastName}
                    </MDTypography>
                  )}
                  {file.address && (
                    <MDTypography variant="subtitle2">
                      {file.address + (file.streetNumber ? `, ${file.streetNumber}` : "")}
                    </MDTypography>
                  )}
                  {file.city && <MDTypography variant="subtitle2">{file.city}</MDTypography>}
                </MDBox>
              </Tooltip>
            </Marker>
          ))}
        {users &&
          users.length > 0 &&
          users.map((user, index) => (
            <Marker
              icon={RED_ICON}
              // eslint-disable-next-line react/no-array-index-key
              key={`marker-openmap-user-${index}`}
              position={{ lat: user.latitude, lng: user.longitude }}
              eventHandlers={{
                click: () => window.open(`/dashboards/profile/${user.id}`, "_blank", "noreferrer"),
              }}
            >
              <Tooltip direction="right" offset={[12, -24]} opacity={1}>
                <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                  {user.name && user.lastName && (
                    <MDTypography variant="subtitle2">
                      {user.name} {user.lastName}
                    </MDTypography>
                  )}
                  {user.address && (
                    <MDTypography variant="subtitle2">
                      {user.address + (user.streetNumber ? `, ${user.streetNumber}` : "")}
                    </MDTypography>
                  )}
                  {user.city && <MDTypography variant="subtitle2">{user.city}</MDTypography>}
                </MDBox>
              </Tooltip>
            </Marker>
          ))}
      </MarkerClusterGroup>
    </>
  );
}

const LONDON_LOCATION = [51.5198994, 0.0278004];

export default function OpenMap({ files = [], users = [], filter = {} }) {
  const center = useMemo(
    () =>
      (files && files.length) || (users && users.length)
        ? getLatLngCenter([
            ...files
              .filter((file) => file.latitude && file.longitude)
              .map((file) => [file.latitude, file.longitude]),
            ...users
              .filter((user) => user.latitude && user.longitude)
              .map((user) => [user.latitude, user.longitude]),
          ])
        : LONDON_LOCATION,
    [files, users]
  );

  return (
    <Card>
      <MapContainer
        center={center}
        maxZoom={18}
        minZoom={3}
        scrollWheelZoom
        zoom={13}
        length={4}
        style={{ width: "100%", height: "70vh", overflow: "hidden" }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Cluster {...{ files, users, center, filter }} />
      </MapContainer>
    </Card>
  );
}
