// @mui material components
// import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import getPublished from "api/news/getPublished";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Pagination from "components/Pagination";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import config from "config/config";

const { imageRoot, baseUrl } = config;

function News() {
  // const [controller, dispatch] = useMaterialUIController();
  const [news, setNews] = useState([]);
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  function refreshNews() {
    getPublished(page, limit)
      .then((res) => {
        if (res.totalPages) {
          setPages(res.totalPages);
        }
        if (res.results && res.results.length > 0) {
          // console.log("res.results", res.results);
          setNews(res.results);
        } else {
          setNews([]);
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }

  useEffect(() => {
    refreshNews();
  }, []);

  useEffect(() => {
    refreshNews();
  }, [page, limit]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="news-list-container">
        {news && news.length > 0
          ? news.map((el, index) => {
              const htmlParsed = el.htmlContent.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
              const dateParsed = `${moment(el.date).format("DD")}-${moment(el.date).format(
                "MM"
              )}-${moment(el.date).format("YYYY")}`;
              return (
                <div className="article-list-container" key={`news-${index.toString()}`}>
                  <img
                    className="article-list-image"
                    alt={el.title}
                    src={imageRoot + el.imageUri}
                  />
                  <div className="article-list-text-container">
                    <div className="article-list-title">{el.title}</div>
                    <div className="article-list-subtitle">{el.subtitle}</div>
                    <div className="article-list-text">{ReactHtmlParser(htmlParsed)}</div>
                    <div className="article-list-date-container">
                      <div className="article-list-date">{dateParsed}</div>
                      <div className="article-list-button-link-container">
                        <IconButton
                          onClick={() => {
                            navigate(`/article?${el.id}`);
                          }}
                          size="small"
                          disableRipple
                        >
                          Leggi
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
    </DashboardLayout>
  );
}

export default News;
