import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController, setMiniSidenav, setUser } from "context";
import createUser from "api/users/createUser";
import getUsers from "api/users/getAllUser";
import getUserByEmail from "api/users/getUserByEmail";
import randomPassword from "utils/randomPassword";
import SweetAlert from "react-bootstrap-sweetalert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Text from "components/custom/form/Text";
import AutoComplete from "components/custom/form/AutoComplete";
import inputs from "components/custom/form/inputs";
import Location from "components/custom/form/Location";

export default function AddUser() {
  const [controller] = useMaterialUIController();
  const [popup, setPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupText, setPopupText] = useState("");
  const [requestSucceded, setRequestSucceded] = useState(false);
  const [onConfirmPopup, setOnConfirmPopup] = useState(null);
  const [consultantList, setConsultantList] = useState([]);
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [createdUserId, setCreatedUserId] = useState("");
  const [step, setStep] = useState(0);
  const [creatingUser, setCreatingUser] = useState({});
  const [checkEmailDisable, setCheckEmailDisable] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  const city = watch("city");
  const address = watch("address");

  useEffect(async () => {
    setConsultantList(
      (await getUsers({ role: "consultant", limit: 100000 })).results
        .map((u) => ({
          id: u.id,
          label: `${u.name || "null"} ${u.lastName || "null"}`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, []);

  function checkEmail(data) {
    const user = Object.fromEntries(Object.entries(data).filter(([key, value]) => !!value));
    if (!user.role) {
      user.role = "user";
    }
    if (!user.password) {
      user.password = randomPassword();
    }
    setOnConfirmPopup(null);
    setCheckEmailDisable(true);
    getUserByEmail(user.email)
      .then((res) => {
        setCreatingUser({});
        setPopup(true);
        setRequestSucceded(false);
        setPopupTitle("Ops!");
        setPopupText(
          "Esiste già un utente registrato con questa email! Vuoi visitare il suo profilo?"
        );
        setOnConfirmPopup(() => () => {
          setPopup(false);
          navigate(`/dashboards/profile/${res.data.id}`);
        });
        setCheckEmailDisable(false);
      })
      .catch((err) => {
        const { code, message } = err.response.data;
        if (code === 404 && message === "User not found") {
          setCreatingUser(user);
          setStep(1);
          reset();
        } else if (code === 400 && message === '"email" must be a valid email') {
          setPopup(true);
          setRequestSucceded(false);
          setPopupTitle("Ops!");
          setPopupText("L'indirizzo email non è stato inserito correttamente!");
        } else {
          setPopup(true);
          setRequestSucceded(false);
          setPopupTitle("Ops!");
          setPopupText("Qualcosa è andato storto, riprova tra qualche minuto!");
        }
        setCheckEmailDisable(false);
      });
  }

  function onSubmit(data) {
    const user = {
      ...creatingUser,
      ...Object.fromEntries(Object.entries(data).filter(([key, value]) => !!value)),
    };
    if (latitude && longitude) {
      user.latitude = latitude;
      user.longitude = longitude;
    }
    if (user?.consultant?.id) {
      user.consultant = user.consultant.id;
    }
    setCreatedUserId("");
    setOnConfirmPopup(null);
    setSubmitDisable(true);
    createUser(user)
      .then((res) => {
        setPopup(true);
        setRequestSucceded(true);
        setPopupTitle("Account creato");
        setPopupText("L'utente è stato creato con successo");
        if (res?.data?.id) {
          setCreatedUserId(res.data.id);
        }
        setSubmitDisable(false);
      })
      .catch((err) => {
        setPopup(true);
        setRequestSucceded(false);
        setPopupTitle("Ops!");
        setPopupText("Qualcosa è andato storto, riprova tra qualche minuto!");
        setSubmitDisable(false);
      });
  }

  return (
    <DashboardLayout>
      {popup && (
        <SweetAlert
          style={{ display: "block" }}
          title={popupTitle}
          success={requestSucceded}
          error={!requestSucceded}
          onConfirm={() => {
            setPopup(false);
            if (requestSucceded && createdUserId) {
              navigate(`/dashboards/profile/${createdUserId}`);
            }
            if (onConfirmPopup) {
              onConfirmPopup();
            }
          }}
          onCancel={() => {
            setPopup(false);
          }}
          confirmBtnText="Ok"
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{ boxShadow: "none" }}
          cancelBtnText="Chiudi"
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{ boxShadow: "none" }}
          showCloseButton
          showCancel={!requestSucceded}
        >
          {popupText}
        </SweetAlert>
      )}
      <DashboardNavbar />
      <Card>
        <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          {step === 0 && (
            <form onSubmit={handleSubmit(checkEmail)}>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "30px",
                  alignItems: "center",
                  width: "max(50%, 600px)",
                }}
                p={5}
              >
                <Text control={control} {...inputs.name} />
                <Text control={control} {...inputs.lastName} />
                <Text control={control} {...inputs.email} />
                <MDButton
                  disabled={checkEmailDisable}
                  type="submit"
                  variant="gradient"
                  color="info"
                >
                  AVANTI
                </MDButton>
              </MDBox>
            </form>
          )}
          {step === 1 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "30px",
                  alignItems: "center",
                  width: "max(50%, 600px)",
                }}
                p={5}
              >
                <Text control={control} {...inputs.phone} />
                <Text control={control} {...inputs.fiscalCode} />
                <Text
                  control={control}
                  {...inputs.city}
                  validation={{ required: { value: true, message: "Inserisci la città" } }}
                />
                <Text
                  control={control}
                  {...inputs.address}
                  validation={{ required: { value: true, message: "Inserisci l' indirizzo" } }}
                />
                <Text
                  control={control}
                  {...inputs.streetNumber}
                  validation={{ required: { value: true, message: "Inserisci il civico" } }}
                />
                <Location
                  city={city}
                  address={address}
                  onChangeLocation={(loc) => {
                    setLatitude(loc.latitude);
                    setLongitude(loc.longitude);
                    if (loc.city) {
                      setValue("city", loc.city);
                    }
                    if (loc.address) {
                      setValue("address", loc.address);
                    }
                  }}
                />
                {controller.user.role === "admin" && (
                  <AutoComplete
                    control={control}
                    name="consultant"
                    label="Consulente"
                    options={consultantList}
                  />
                )}
                <MDButton disabled={submitDisable} type="submit" variant="gradient" color="info">
                  AGGIUNGI
                </MDButton>
              </MDBox>
            </form>
          )}
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
