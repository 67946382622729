import { isEmail, isValidPassword, isPhoneNumber, isFiscalCode } from "utils/ValidateFields";
import Joi from "joi";
import { tlds } from "@hapi/tlds";

const name = {
  name: "name",
  label: "Nome",
  type: "text",
  defaultValue: "",
  validation: {
    required: {
      value: true,
      message: "Inserisci il nome",
    },
  },
};

const lastName = {
  name: "lastName",
  label: "Cognome",
  type: "text",
  defaultValue: "",
  validation: {
    required: {
      value: true,
      message: "Inserisci il cognome",
    },
  },
};

const email = {
  name: "email",
  label: "Email",
  type: "text",
  defaultValue: "",
  validation: {
    required: {
      value: true,
      message: "Inserisci l'indirizzo email",
    },
    // validate: (v) => isEmail(v) || "Inserisci un indirizzo email valido",
    validate: (v) => {
      try {
        Joi.assert(v, Joi.string().email({ tlds: { allow: tlds } }));
      } catch (err) {
        return "Inserisci un indirizzo email valido";
      }
      return true;
    },
  },
};

const password = {
  name: "password",
  label: "Password",
  type: "password",
  defaultValue: "",
  validation: {
    required: {
      value: true,
      message: "Inserisci la password",
    },
    minLength: {
      value: 8,
      message: "Inserisci una password lunga almeno 8 caratteri",
    },
    validate: (v) =>
      isValidPassword(v) ||
      "Inserisci almeno un numero, una lettera minuscola e una lettera maiuscola",
  },
};

const modifyPassword = {
  name: "password",
  label: "Password",
  type: "password",
  defaultValue: "",
  validation: {
    validate: (v) => {
      if (v) {
        if (v.length < 8) {
          return "Inserisci una password lunga almeno 8 caratteri";
        }
        if (!isValidPassword(v)) {
          return "Inserisci almeno un numero, una lettera minuscola e una lettera maiuscola";
        }
      }
      return null;
    },
  },
};

const phone = {
  name: "phone",
  label: "Telefono",
  type: "text",
  defaultValue: "",
  validation: {
    validate: (v) => !v || isPhoneNumber(v) || "Inserisci un numero di telefono valido",
  },
};

const mobilePhone = {
  name: "mobilePhone",
  label: "Cellulare",
  type: "text",
  defaultValue: "",
  validation: {
    validate: (v) => !v || isPhoneNumber(v) || "Inserisci un numero di cellulare valido",
  },
};

const fiscalCode = {
  name: "fiscalCode",
  label: "Codice fiscale",
  type: "text",
  defaultValue: "",
  validation: {
    validate: (v) => !v || isFiscalCode(v) || "Inserisci un codice fiscale valido",
  },
};

const city = {
  name: "city",
  label: "Città",
  type: "text",
  defaultValue: "",
};

const address = {
  name: "address",
  label: "Indirizzo",
  type: "text",
  defaultValue: "",
};

const streetNumber = {
  name: "streetNumber",
  label: "Civico",
  type: "text",
  defaultValue: "",
};

const accountAppRisparmio = {
  name: "accountAppRisparmio",
  label: "Account AppRisparmio",
  type: "text",
  defaultValue: "",
};

export default {
  name,
  lastName,
  email,
  password,
  modifyPassword,
  phone,
  mobilePhone,
  fiscalCode,
  city,
  address,
  streetNumber,
  accountAppRisparmio,
};
