import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import getUsers from "api/users/getAllUser";
import getAllUsersAppRisparmio from "api/users/getAllUsersAppRisparmio";
import associateAppRisparmioUser from "api/users/associateAppRisparmioUser";

function AssociateCustomer() {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [error, setError] = useState("");
  const [unassociatedUsersList, setUnassociatedUsersList] = useState([]);
  const [appRisparmioUsersList, setAppRisparmioUsersList] = useState([]);
  const [userSearchBy, setUserSearchBy] = useState("email");
  const [appRisparmioSearchBy, setAppRisparmioSearchBy] = useState("email");
  const [userComparabili, setUserComparabili] = useState("");
  const [userAppRisparmio, setUserAppRisparmio] = useState("");

  useEffect(() => {
    getUsers({
      isNotAppRisparmioAssociated: true,
      role: "user",
      limit: 100000,
    })
      .then((res) => {
        setUnassociatedUsersList(res.results);
      })
      .catch((err) => {
        // console.log(err);
      });
    getAllUsersAppRisparmio()
      .then((res) => {
        // console.log("response", res);
        setAppRisparmioUsersList(res.users);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  function getAutocomplete(options, searchBy, editFunction) {
    return (
      <Autocomplete
        disablePortal
        options={options}
        getOptionLabel={(option) => {
          switch (searchBy) {
            case "id":
              return option.id;
            case "email":
              return option.email;
            case "fullName":
              return `${option.name} ${option.lastName}`;
            default:
              return option.id;
          }
        }}
        renderInput={(params) => (
          <TextField {...params} sx={{ height: "33px" }} variant="standard" />
        )}
        onChange={(el, value) => {
          if (value) {
            editFunction(value.id);
          } else {
            editFunction("");
          }
        }}
        sx={{ marginLeft: "16px", width: "300px" }}
      />
    );
  }

  return (
    <DashboardLayout>
      {popup ? (
        <SweetAlert
          style={{ display: "block" }}
          title="Account associato"
          success
          onConfirm={() => {
            setPopup(false);
            navigate(`/dashboards/profile/${userComparabili}`);
          }}
          confirmBtnText="Ok"
          confirmBtnStyle={{ boxShadow: "none", color: "blue" }}
        >
          {/* eslint-disable-next-line */}
          L'account Comparabili e l'account AppRisparmio sono stati correttamente associati!
        </SweetAlert>
      ) : null}
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MDBox
            variant="gradient"
            p={5}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "32px",
            }}
          >
            <MDBox>
              <MDTypography variant="subtitle1">Seleziona utente Comparabili:</MDTypography>

              <MDBox style={{ display: "flex", alignItems: "flex-start" }}>
                {/* <MDTypography variant="subtitle1">Ricerca utente Comparabili per:</MDTypography> */}
                <Select
                  sx={{ height: "33px" }}
                  variant="standard"
                  value={userSearchBy}
                  onChange={(el) => setUserSearchBy(el.target.value)}
                  style={{ marginLeft: "16px" }}
                >
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="id">ID</MenuItem>
                  <MenuItem value="fullName">Nome</MenuItem>
                </Select>
                {getAutocomplete(unassociatedUsersList, userSearchBy, setUserComparabili)}
              </MDBox>
            </MDBox>
            <MDBox>
              <MDTypography variant="subtitle1">Seleziona utente AppRisparmio:</MDTypography>
              <MDBox style={{ display: "flex", alignItems: "flex-start" }}>
                {/* <MDTypography variant="subtitle1">Ricerca utente AppRisparmio per:</MDTypography> */}
                <Select
                  sx={{ height: "33px" }}
                  variant="standard"
                  value={appRisparmioSearchBy}
                  onChange={(el) => setAppRisparmioSearchBy(el.target.value)}
                  style={{ marginLeft: "16px" }}
                >
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="id">ID</MenuItem>
                </Select>
                {getAutocomplete(appRisparmioUsersList, appRisparmioSearchBy, setUserAppRisparmio)}
              </MDBox>
            </MDBox>
            <MDButton
              style={{ alignSelf: "flex-end" }}
              variant="gradient"
              color="info"
              onClick={() => {
                associateAppRisparmioUser({ userId: userComparabili, arUserId: userAppRisparmio })
                  .then((res) => {
                    setPopup(true);
                  })
                  .catch((err) => {
                    setError(err.message);
                  });
              }}
            >
              ASSOCIA
            </MDButton>
            {error ? (
              <MDTypography color="error" variant="body2">
                {error}
              </MDTypography>
            ) : null}
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AssociateCustomer;
