import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useMaterialUIController } from "context";

import getTokens from "api/getTokens";

import mfaQrCode from "api/auth/mfaQrCode";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import twoFactor from "api/auth/twoFactor";

function Mfa() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    const tokens = getTokens();
    if (user && !user.mfaEnabled) {
      mfaQrCode(user)
        .then((res) => {
          const file = res;
          // Encode the file using the FileReader API
          const reader = new FileReader(file);
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setQrCode(reader.result);
            // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
          };
          // console.log("imageToDisplay", imageToDisplay);
          // setQrCode(res);
        })
        // eslint-disable-next-line
        .catch((err) => console.log("error mfaQrCode", err));
    } else if (user && user.mfaVerified && tokens.mfa) {
      window.location.href = "/";
    }
  }, [user]);

  const validateTwoFactor = () => {
    // console.log("email ", email);
    // console.log("password ", password);
    if (user) {
      // const dataToSend = {
      //   user
      //   twoFactorCode,
      // };
      return twoFactor(user, twoFactorCode)
        .then((res) => {
          // eslint-disable-next-line
          console.log("res two factor", res);
          if (res) {
            window.location.href = "/";
          } else {
            window.location.href = "/mfa";
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("error login", err);
          window.location.href = "/mfa";
        });
    }
    return null;
  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  // TODO gestire errori

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Verifica a 2 fattori
          </MDTypography>
        </MDBox>
        {qrCode ? <img src={qrCode} title="ciao" alt="ciao" /> : null}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="input"
                label="Two Factor Code"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    validateTwoFactor();
                    // performLogin();
                  }
                }}
                onChange={(e) => {
                  setTwoFactorCode(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  validateTwoFactor();
                }}
              >
                Verifica
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Mfa;
