// @mui material components
// import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AddLocation from "components/custom/geocoding/AddLocation";
import AddMortgage from "components/custom/mortgage/AddMortgage";
import CustomDropdown from "components/custom/CustomDropdown";
import { useMaterialUIController } from "context";
import escapeRegex from "utils/escapeRegex";
import downloadDoc from "utils/downloadDoc";

function filterFiles(files, params) {
  if (!files || files.length === 0) {
    return { results: [], totalPages: 0, totalResults: 0 };
  }
  const fields = ["name", "date", "section", "category", "city", "address", "streetNumber"];
  const validOpts = ["page", "limit", "sortBy"];
  const filters = Object.entries(params).filter(([key, value]) => fields.includes(key));
  const options = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => validOpts.includes(key))
  );
  const elems = files
    .map((value) => {
      const nameSplit = value.key.split("/");
      return {
        ...value,
        name: value.filename || (nameSplit ? nameSplit[nameSplit.length - 1] : "name"),
        date: new Date(value.timestamp).toLocaleDateString(),
        section: value.category,
        category: value.subCategory,
      };
    })
    .filter((doc) =>
      filters.every(([key, value]) => new RegExp(`.*${escapeRegex(value)}.*`, "i").test(doc[key]))
    );
  if (options.sortBy) {
    let sort = options.sortBy;
    let desc = false;
    if (options.sortBy.startsWith("-")) {
      sort = options.sortBy.slice(1);
      desc = true;
    }
    if (fields.includes(sort)) {
      if (desc) {
        elems.sort((a, b) => b[sort].localeCompare(a[sort]));
      } else {
        elems.sort((a, b) => a[sort].localeCompare(b[sort]));
      }
    }
  }
  if (!options.page || options.page < 1) {
    options.page = 1;
  }
  if (!options.limit || options.limit < 1) {
    options.limit = 1;
  }
  const start = (options.page - 1) * options.limit;
  const end = Math.min(start + options.limit, elems.length);
  const wow = {
    results: elems.slice(start, end),
    totalPages: Math.ceil(elems.length / options.limit),
    totalResults: elems.length,
  };
  return wow;
}

function CustomList({
  elemsGetter,
  elemFields,
  dropdown = false,
  defaultSelectedKeys = [],
  userRole,
  linkGetter,
  linkIcon,
  action,
  defaultLimit = 10,
  consultantList,
  deleteAction,
  locationAction,
  forceUpdate,
  mortgageAction,
  defaultSortBy,
  showFiles,
  editPracticeAction,
  practiceStatus = false,
}) {
  const [controller] = useMaterialUIController();
  const [elems, setElems] = useState([]);
  const [sortBy, setSortBy] = useState(defaultSortBy || "-updatedAt");
  const [filterBy, setFilterBy] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [deleteElem, setDeleteElem] = useState(null);
  const [locationPopup, setLocationPopup] = useState(false);
  const [locationElem, setLocationElem] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys || []);
  const [mortgagePopup, setMortgagePopup] = useState(false);
  const [mortgageElem, setMortgageElem] = useState(null);
  const [userFiles, setUserFiles] = useState(null);
  const navigate = useNavigate();

  const widths = {
    name: "10%",
    lastName: "10%",
    email: "15%",
    phone: "10%",
    mobilePhone: "10%",
    fiscalCode: "10%",
    city: "10%",
    address: "15%",
    streetNumber: "5%",
    file: "10%",
    date: "5%",
    createdAt: "5%",
    updatedAt: "5%",
    category: "10%",
    subCategory: "10%",
    consultant: "15%",
  };

  let consultants = [];
  if (consultantList) {
    consultants = consultantList
      .map((u) => ({
        value: u.id,
        label: `${u.name} ${u.lastName}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  useEffect(() => {
    const params = {
      page,
      limit,
      sortBy,
      role: userRole,
    };
    Object.entries(filterBy).forEach(([key, value]) => {
      params[key] = value;
    });
    elemsGetter(params)
      .then((res) => {
        setElems(res.results);
        setTotalPages(res.totalPages);
        setTotalResults(res.totalResults);
        if (res.totalPages < page) {
          setPage(Math.max(res.totalPages, 1));
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, [userRole, sortBy, filterBy, page, limit, forceUpdate]);

  function isColumnVisible(key) {
    if (!dropdown || !selectedKeys || !selectedKeys.length) {
      return true;
    }
    return selectedKeys.includes(key);
  }

  function getHeaderSortElement(key, label, uniqueKey) {
    return (
      <th
        style={{
          padding: "10px 0",
          border: "none",
          width: widths[key] || "5%",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          if (sortBy === key) {
            setSortBy(`-${key}`);
          } else {
            setSortBy(key);
          }
        }}
        key={uniqueKey}
      >
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDTypography fontWeight="medium" variant="heading6" textTransform="uppercase">
            {label}
          </MDTypography>
          {sortBy === key && <Icon fontSize="medium">south</Icon>}
          {sortBy === `-${key}` && <Icon fontSize="medium">north</Icon>}
        </div>
      </th>
    );
  }

  function getHeaderFilterElement(key, label, uniqueKey) {
    if (
      !key ||
      key === "createdAt" ||
      key === "updatedAt" ||
      key === "lastUpdate" ||
      key === "status"
    ) {
      return (
        <td
          style={{
            padding: "10px",
            border: "none",
            width: widths[key] || "5%",
            textAlign: "center",
          }}
          key={uniqueKey}
        >
          /
        </td>
      );
    }
    if (key === "adduser") {
      return (
        <td
          style={{
            padding: "10px",
            border: "none",
            width: widths[key] || "5%",
            textAlign: "center",
          }}
          key={uniqueKey}
        >
          {controller.user.role !== "operator" ? (
            <Tooltip title="Aggiungi cliente">
              <IconButton
                size="small"
                onClick={() => navigate(`/${controller.user.role}/add${userRole || "user"}`)}
              >
                <Icon>person_add</Icon>
              </IconButton>
            </Tooltip>
          ) : (
            "/"
          )}
        </td>
      );
    }
    return (
      <td
        style={{
          padding: "10px 0",
          border: "none",
          width: widths[key] || "5%",
          textAlign: "center",
        }}
        key={uniqueKey}
      >
        <TextField
          type="input"
          label={label}
          fullWidth
          onChange={(e) => {
            if (e.target.value) {
              const newFilterBy = { ...filterBy };
              newFilterBy[key] = e.target.value;
              setFilterBy(newFilterBy);
            } else {
              const delFilterBy = { ...filterBy };
              if (key in delFilterBy) {
                delete delFilterBy[key];
              }
              setFilterBy(delFilterBy);
            }
          }}
        />
      </td>
    );
  }

  function getValueElement(el, key, uniqueKey) {
    if (key === "status") {
      return (
        <td style={{ borderBottom: "solid 1px #dedede" }} key={uniqueKey}>
          {el[key] ? "Completato" : "Non completato"}
        </td>
      );
    }
    return (
      <td style={{ borderBottom: "solid 1px #dedede" }} key={uniqueKey}>
        {(key === "createdAt" || key === "updatedAt" || key === "lastUpdate") && el[key]
          ? new Date(Date.parse(el[key])).toLocaleString()
          : el[key]}
      </td>
    );
  }

  function displayHeaderSortElement() {
    return (
      <>
        {elemFields
          .filter((field) => isColumnVisible(field.name))
          .map((field, index) =>
            getHeaderSortElement(field.name, field.label, `sort-${field.name}-${index}`)
          )}
      </>
    );
  }

  function getAutocomplete(options, editFunction, uniqueKey, defaultValue = null) {
    return (
      <td
        style={{
          padding: "10px 0",
          border: "none",
          width: widths.consultant || "10%",
          textAlign: "center",
        }}
        key={uniqueKey}
      >
        <Autocomplete
          fullWidth
          disablePortal
          options={options}
          getOptionLabel={(option) => option.label}
          defaultValue={defaultValue}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField {...params} label="Consulente" InputProps={{ sx: { height: 44 } }} />
          )}
          onChange={(el, value) => {
            if (value) {
              editFunction(value.value);
            } else {
              editFunction(defaultValue);
            }
          }}
        />
      </td>
    );
  }

  function displayHeaderFilterElement() {
    return (
      <>
        {elemFields
          .filter((field) => isColumnVisible(field.name))
          .map((field, index) => {
            if (field.name === "consultant") {
              return getAutocomplete(
                consultants,
                (value) => {
                  if (value) {
                    const newFilterBy = { ...filterBy };
                    newFilterBy.consultant = value;
                    setFilterBy(newFilterBy);
                  } else {
                    const delFilterBy = { ...filterBy };
                    if ("consultant" in delFilterBy) {
                      delete delFilterBy.consultant;
                    }
                    setFilterBy(delFilterBy);
                  }
                },
                `filter-${field.name}-${index}`
              );
            }
            return getHeaderFilterElement(field.name, field.label, `filter-${field.name}-${index}`);
          })}
      </>
    );
  }

  function displayValueElement(el) {
    return (
      <>
        {elemFields
          .filter((field) => isColumnVisible(field.name))
          .map((field, index) => {
            if (field.name === "consultant") {
              return (
                <td
                  style={{ borderBottom: "solid 1px #dedede" }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`value-${field.name}-${index}`}
                >
                  {el.consultant ? `${el.consultant.name} ${el.consultant.lastName}` : ""}
                </td>
              );
            }
            return getValueElement(el, field.name, `value-${field.name}-${index}`);
          })}
      </>
    );
  }

  return (
    <>
      {userFiles && (
        <Dialog fullWidth maxWidth="lg" open={!!userFiles}>
          <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
            Documenti
            <IconButton
              onClick={() => {
                setUserFiles(null);
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <CustomList
              elemsGetter={async (params) => filterFiles(userFiles, params)}
              elemFields={[
                { name: "name", label: "File" },
                { name: "date", label: "Data" },
                { name: "section", label: "Sezione" },
                { name: "category", label: "Categoria" },
                { name: "city", label: "Città" },
                { name: "address", label: "Indirizzo" },
                { name: "streetNumber", label: "Civico" },
              ]}
              userRole={userRole}
              consultantList={consultantList}
              dropdown
              action={downloadDoc}
              linkIcon="download"
              defaultSelectedKeys={["name", "date", "section", "category"]}
              defaultSortBy="-date"
              forceUpdate={forceUpdate}
            />
          </DialogContent>
        </Dialog>
      )}
      <Dialog fullWidth maxWidth="xs" open={mortgagePopup}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Inserisci rata mutuo
          <IconButton
            onClick={() => {
              setMortgagePopup(false);
              setMortgageElem(null);
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AddMortgage
            action={(mortgage) => {
              mortgageAction(mortgageElem, mortgage).catch((err) => {
                // console.log(err);
              });
              setMortgagePopup(false);
              setMortgageElem(null);
            }}
            cancel={() => {
              setMortgagePopup(false);
              setMortgageElem(null);
            }}
            defaultValues={mortgageElem?.recurringTransaction}
            onlyView={!!mortgageElem?.recurringTransaction}
          />
        </DialogContent>
      </Dialog>
      <Dialog fullWidth maxWidth="xs" open={locationPopup}>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Assegna posizione
          <IconButton
            onClick={() => {
              setLocationPopup(false);
              setLocationElem(null);
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AddLocation
            action={(location) => {
              locationAction(locationElem, location).catch((err) => {
                // console.log(err);
              });
              setLocationPopup(false);
              setLocationElem(null);
            }}
            cancel={() => {
              setLocationPopup(false);
              setLocationElem(null);
            }}
            defaultValues={locationElem}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={cancelPopup}>
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Eliminazione documento
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler cancellare il documento {deleteElem ? deleteElem.name : ""}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setCancelPopup(false);
              setDeleteElem(null);
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={() => {
              setCancelPopup(false);
              setDeleteElem(null);
              deleteAction(deleteElem);
            }}
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
      {dropdown && (
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <CustomDropdown
            options={elemFields}
            defaultSelectedKeys={defaultSelectedKeys}
            selectedOnChange={(selected) => {
              const removedKeys = selectedKeys.filter((key) => !selected.includes(key));
              const delFilterBy = { ...filterBy };
              removedKeys
                .filter((key) => key in delFilterBy)
                .forEach((key) => {
                  delete delFilterBy[key];
                });
              if (Object.keys(filterBy).length !== Object.keys(delFilterBy).length) {
                setFilterBy(delFilterBy);
              }
              setSelectedKeys(selected);
            }}
          />
        </div>
      )}
      <div className="table_users_list">
        <table style={{ width: "100% " }}>
          <thead>
            <tr>
              {displayHeaderSortElement()}
              {editPracticeAction && (
                <th
                  style={{
                    padding: "10px 0",
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontWeight="medium" variant="heading6" textTransform="uppercase">
                    Modifica
                  </MDTypography>
                </th>
              )}
              {mortgageAction && (
                <th
                  style={{
                    padding: "10px 0",
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontWeight="medium" variant="heading6" textTransform="uppercase">
                    Mutuo
                  </MDTypography>
                </th>
              )}
              {locationAction && (
                <th
                  style={{
                    padding: "10px 0",
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontWeight="medium" variant="heading6" textTransform="uppercase">
                    Posizione
                  </MDTypography>
                </th>
              )}
              {showFiles && (
                <th
                  style={{
                    padding: "10px 0",
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontWeight="medium" variant="heading6" textTransform="uppercase">
                    Documenti
                  </MDTypography>
                </th>
              )}
              {(linkGetter || action) && (
                <th
                  style={{
                    padding: "10px 0",
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontWeight="medium" variant="heading6" textTransform="uppercase">
                    {linkGetter ? "Vai al profilo" : "Scarica"}
                  </MDTypography>
                </th>
              )}
              {deleteAction && (
                <th
                  style={{
                    padding: "10px 0",
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontWeight="medium" variant="heading6" textTransform="uppercase">
                    Elimina
                  </MDTypography>
                </th>
              )}
              {practiceStatus && (
                <th
                  style={{
                    padding: "10px 0",
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <MDTypography fontWeight="medium" variant="heading6" textTransform="uppercase">
                    Vai alle pratiche
                  </MDTypography>
                </th>
              )}
            </tr>
          </thead>
          <tbody style={{ fontSize: "14px", textAlign: "center" }}>
            <tr>
              {displayHeaderFilterElement()}
              {editPracticeAction ? getHeaderFilterElement(null) : null}
              {mortgageAction ? getHeaderFilterElement(null) : null}
              {locationAction ? getHeaderFilterElement(null) : null}
              {showFiles ? getHeaderFilterElement(null) : null}
              {linkGetter ? getHeaderFilterElement("adduser") : null}
              {action ? getHeaderFilterElement(null) : null}
              {deleteAction ? getHeaderFilterElement(null) : null}
              {practiceStatus ? getHeaderFilterElement(null) : null}
            </tr>
            {elems.map((el, index) => (
              <tr style={{ border: "none" }} key={`user-${index.toString()}`}>
                {displayValueElement(el)}
                {editPracticeAction && (
                  <td
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Modifica pratica">
                      <IconButton onClick={() => editPracticeAction(el)}>
                        <Icon>edit</Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                )}
                {showFiles && (
                  <td
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Visualizza documenti">
                      <IconButton
                        onClick={() => {
                          if (controller.user.role === "operator") {
                            setUserFiles(
                              el.files.filter(({ category }) => category === "immobile")
                            );
                          } else {
                            setUserFiles(el.files);
                          }
                        }}
                      >
                        <Icon>description</Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                )}
                {linkGetter && (
                  <td
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Visualizza profilo">
                      <IconButton
                        onClick={() => window.open(linkGetter(el), "_blank", "noreferrer")}
                      >
                        <Icon>{linkIcon || "person"}</Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                )}
                {mortgageAction && (
                  <td
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Inserisci rata mutuo">
                      <IconButton
                        onClick={() => {
                          setMortgagePopup(true);
                          setMortgageElem(el);
                        }}
                      >
                        <Icon>
                          {el?.recurringTransaction ? "account_balance" : "account_balance"}
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                )}
                {locationAction && (
                  <td
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title={el && el.city ? "Modifica posizione" : "Aggiungi posizione"}>
                      <IconButton
                        onClick={() => {
                          setLocationPopup(true);
                          setLocationElem(el);
                        }}
                      >
                        <Icon>{el && el.city ? "edit_location_alt" : "add_location_alt"}</Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                )}
                {action && (
                  <td
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title={linkIcon ? "Scarica documento" : "Visualizza profilo"}>
                      <IconButton onClick={() => action(el)}>
                        <Icon>{linkIcon || "person"}</Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                )}
                {deleteAction && (
                  <td
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Elimina documento">
                      <IconButton
                        onClick={() => {
                          setDeleteElem(el);
                          setCancelPopup(true);
                        }}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                )}
                {practiceStatus && (
                  <td
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="Vai alle pratiche">
                      <IconButton
                        onClick={() => {
                          window.open(`/practices-status/${encodeURIComponent(el.id)}`, "_blank");
                        }}
                      >
                        <Icon>checklist</Icon>
                      </IconButton>
                    </Tooltip>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
          {totalPages > 0 && (
            <>
              <MDTypography variant="body2">
                {limit * (page - 1) + 1}-{Math.min(limit * page, totalResults)} of {totalResults}
              </MDTypography>
              <IconButton disabled={page <= 1} onClick={() => setPage(1)}>
                <Icon color={page <= 1 ? "disabled" : "action"}>skip_previous</Icon>
              </IconButton>
              <IconButton
                disabled={page <= 1}
                onClick={() => setPage((current) => Math.max(1, current - 1))}
              >
                <Icon color={page <= 1 ? "disabled" : "action"}>keyboard_arrow_left</Icon>
              </IconButton>
              <IconButton
                disabled={page >= totalPages}
                onClick={() => setPage((current) => Math.min(totalPages, current + 1))}
              >
                <Icon color={page >= totalPages ? "disabled" : "action"}>keyboard_arrow_right</Icon>
              </IconButton>
              <IconButton disabled={page >= totalPages} onClick={() => setPage(totalPages)}>
                <Icon color={page >= totalPages ? "disabled" : "action"}>skip_next</Icon>
              </IconButton>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CustomList;
