import config from "config/config";
import getTokens from "api/getTokens";
import axios from "axios";

const { baseUrl } = config;

export default async function getUserByEmail(email) {
  const endpoint = `${baseUrl}/v1/users/byemail`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  return axios.post(
    endpoint,
    { email },
    {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": `${baseUrl}`,
        "Access-Control-Allow-Credentials": true,
        Authorization: `bearer ${access}`,
      },
    }
  );
}
