import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutoComplete({
  control,
  name,
  label,
  validation = {},
  defaultValue = null,
  options = [],
  AutocompleteProps = {},
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={validation}
      render={({ field, fieldState, formState }) => (
        <Autocomplete
          {...field}
          onChange={(el, value) => field.onChange(value)}
          fullWidth
          disablePortal
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option?.label || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!formState.errors[name]}
              helperText={formState.errors[name] && formState.errors[name].message}
            />
          )}
          {...AutocompleteProps}
        />
      )}
    />
  );
}
