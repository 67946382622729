import { useState } from "react";
import { useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import Location from "components/custom/form/Location";
import Text from "components/custom/form/Text";
import CustomSelect from "components/custom/form/CustomSelect";
import Amount from "components/custom/form/Amount";
import inputs from "components/custom/form/inputs";
import moment from "moment";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TextField } from "@mui/material";
import CustomDate from "components/custom/form/CustomDate";

export default function AddMortgage({
  action,
  cancel,
  defaultValues = {},
  cancelButtonLabel = "annulla",
  submitButtonLabel = "salva",
  onlyView = false,
}) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: Object.fromEntries(
      Object.entries(defaultValues || {}).filter(([k, v]) =>
        ["amount", "startDate", "repetitionInterval"].includes(k)
      )
    ),
  });

  function onSubmit(data) {
    // console.log({
    //   ...data,
    //   startDate: data.startDate.hours(12).minutes(0).seconds(0).toISOString(),
    // });
    action({ ...data, startDate: data.startDate.hours(12).minutes(0).seconds(0).toISOString() });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MDBox
        sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "32px" }}
      >
        <Amount
          control={control}
          name="amount"
          label="Importo *"
          validation={{
            required: { value: true, message: "Inserisci l'ammontare della rataaaaaa" },
            validate: (v) => v > 0 || "Inserisci l'ammontare della rata",
          }}
          defaultValue={0}
          TextFieldProps={{
            sx: { mt: "16px", width: "max(50%, 200px)" },
            InputProps: {
              readOnly: !!onlyView,
            },
          }}
        />
        <CustomDate
          control={control}
          name="startDate"
          label="Data di inizio *"
          readOnly={!!onlyView}
          validation={{
            required: { value: true, message: "Inserisci la data di inizio" },
            validate: (v) => !!v.toISOString() || "Inserisci una data valida",
          }}
          BoxProps={{ sx: { "& .MuiFormControl-root": { width: "max(50%, 200px)" } } }}
        />
        <CustomSelect
          control={control}
          name="repetitionInterval"
          label="Ricorrenza *"
          options={[
            { value: "week", label: "Settimanale" },
            { value: "15days", label: "Quindicinale" },
            { value: "month", label: "Mensile" },
            { value: "year", label: "Annuale" },
          ]}
          defaultValue="month"
          validation={{ required: { value: true, message: "Inserisci ricorrenza mutuo" } }}
          SelectProps={{ inputProps: { readOnly: !!onlyView } }}
          FormControlProps={{ sx: { width: "max(50%, 200px)" } }}
        />
        <MDBox sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-around" }}>
          {cancel && (
            <MDButton variant="text" color="info" onClick={cancel}>
              {cancelButtonLabel}
            </MDButton>
          )}
          {!onlyView && (
            <MDButton type="submit" variant="gradient" color="info">
              {submitButtonLabel}
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </form>
  );
}
