/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import SweetAlert from "react-bootstrap-sweetalert";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import editUser from "api/users/editUser";
import getUsers from "api/users/getAllUser";
import verifyEmail from "api/auth/verifyEmail";

import Text from "components/custom/form/Text";
import AutoComplete from "components/custom/form/AutoComplete";
import inputs from "components/custom/form/inputs";
import Location from "components/custom/form/Location";

import { useMaterialUIController } from "context";

function ProfileInfoCard({ title, shadow, user, canEdit, onConfirmEdit }) {
  const [controller] = useMaterialUIController();
  const [editElement, setEditElement] = useState(null);
  const [popup, setPopup] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [consultantList, setConsultantList] = useState([]);
  const [popupErrorMessage, setPopupErrorMessage] = useState("");
  const [popupEmailSent, setPopupEmailSent] = useState(false);
  const [popupEmailContent, setPopupEmailContent] = useState(null);
  const labels = [];
  const values = [];
  const defaultValues = useMemo(() => {
    const def = { ...user };
    if (def.consultant) {
      def.consultant = {
        id: def.consultant.id,
        label: `${def.consultant.name} ${def.consultant.lastName}`,
      };
    }
    return def;
  }, [user]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues,
  });
  const city = watch("city");
  const address = watch("address");
  const [latitude, setLatitude] = useState(defaultValues.latitude || 0);
  const [longitude, setLongitude] = useState(defaultValues.longitude || 0);
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  function sendEmailVerification() {
    const successElem = (
      <MDTypography variant="Body 1">Email di verifica inviata con successo!</MDTypography>
    );
    const failElem = (
      <MDTypography variant="Body 1">
        Si è verificato un errore nell&apos; invio dell&apos; email di verifica. Riprova più tardi!
      </MDTypography>
    );
    verifyEmail(user)
      .then(() => {
        setPopupEmailContent(
          <div style={{ padding: "16px", textAlign: "center" }}>{successElem}</div>
        );
        setPopupEmailSent(true);
      })
      .catch(() => {
        setPopupEmailContent(
          <div style={{ padding: "16px", textAlign: "center" }}>{failElem}</div>
        );
        setPopupEmailSent(true);
      });
  }

  useEffect(async () => {
    setConsultantList(
      (await getUsers({ role: "consultant", limit: 100000 })).results
        .map((u) => ({
          id: u.id,
          label: `${u.name} ${u.lastName}`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, []);

  const info = {};
  if (user.role === "user") {
    info.Nome = user.name;
    info.Cognome = user.lastName;
    info["Codice fiscale"] = user.fiscalCode;
    info.Telefono = user.phone;
    info.Email = user.email;
    info["Email verificata"] = user.isEmailVerified;
    // info["Account App Risparmio"] = user.accountAppRisparmio;
    // info["Account App Risparmio associato"] = user.isAppRisparmioAssociated;
    info["Città"] = user.city;
    info.Indirizzo = user.address;
    info.Consulente = user.consultant
      ? `${user.consultant.name} ${user.consultant.lastName}`
      : "N/A";
  } else if (user.role === "consultant") {
    info.Nome = user.name;
    info.Cognome = user.lastName;
    info.Email = user.email;
    info.Telefono = user.phone;
    // info.Cellulare = user.mobilePhone;
    // info["Telefono ufficio"] = user.officePhone;
    info["Città"] = user.city;
  } else {
    info.Nome = user.name;
    info.Cognome = user.lastName;
    info.Email = user.email;
    info.Telefono = user.phone;
  }

  if (controller.user.role === "operator") {
    Object.keys(info).forEach((field) => {
      if (!["Nome", "Cognome"].includes(field)) {
        delete info[field];
      }
    });
  }

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);
      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" alignItems="center" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      {(() => {
        switch (label.trim().toLowerCase()) {
          case "email verificata":
            return (
              <>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  &nbsp;{values[key] ? "SI" : "NO"}
                </MDTypography>
                {values[key] ? null : (
                  <IconButton
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    onClick={sendEmailVerification}
                  >
                    <Icon fontSize="small">mail</Icon>
                  </IconButton>
                )}
              </>
            );
          case "account app risparmio associato":
            return (
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{values[key] ? "SI" : "NO"}
              </MDTypography>
            );
          default:
            return (
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{values[key]}
              </MDTypography>
            );
        }
      })()}
    </MDBox>
  ));

  function inputByRole() {
    switch (user.role) {
      case "user":
        return (
          <>
            <Text control={control} {...inputs.name} />
            <Text control={control} {...inputs.lastName} />
            <Text control={control} {...inputs.email} />
            <Text control={control} {...inputs.modifyPassword} />
            {controller.user.role === "admin" && (
              <Text control={control} {...inputs.accountAppRisparmio} />
            )}
            <Text control={control} {...inputs.phone} />
            <Text control={control} {...inputs.fiscalCode} />
            <Text
              control={control}
              {...inputs.city}
              validation={{ required: { value: true, message: "Inserisci la città" } }}
            />
            <Text
              control={control}
              {...inputs.address}
              validation={{ required: { value: true, message: "Inserisci l' indirizzo" } }}
            />
            <Text
              control={control}
              {...inputs.streetNumber}
              validation={{ required: { value: true, message: "Inserisci il civico" } }}
            />
            <Location
              city={city}
              address={address}
              onChangeLocation={(loc) => {
                setLatitude(loc.latitude);
                setLongitude(loc.longitude);
                if (loc.city) {
                  setValue("city", loc.city);
                }
                if (loc.address) {
                  setValue("address", loc.address);
                }
              }}
              defaultValues={{
                latitude: defaultValues.latitude || 0,
                longitude: defaultValues.longitude || 0,
              }}
            />
            {controller.user.role === "admin" && (
              <AutoComplete
                control={control}
                name="consultant"
                label="Consulente"
                options={consultantList}
                defaultValue={consultantList.find(
                  (cons) => cons.id === defaultValues?.consultant?.id
                )}
              />
            )}
          </>
        );
      case "consultant":
        return (
          <>
            <Text control={control} {...inputs.name} />
            <Text control={control} {...inputs.lastName} />
            <Text control={control} {...inputs.email} />
            {/* <Text control={control} {...inputs.modifyPassword} /> */}
            <Text control={control} {...inputs.phone} />
            {/* <Text control={control} {...inputs.mobilePhone} /> */}
            <Text control={control} {...inputs.city} />
          </>
        );
      default:
        return (
          <>
            <Text control={control} {...inputs.name} />
            <Text control={control} {...inputs.lastName} />
            <Text control={control} {...inputs.email} />
            <Text control={control} {...inputs.modifyPassword} />
          </>
        );
    }
  }

  function handlePopupClose() {
    setPopup(false);
    setEditElement(null);
    setLatitude(defaultValues.latitude || 0);
    setLongitude(defaultValues.longitude || 0);
    reset();
  }

  function onSubmit(data) {
    const u = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value && value !== defaultValues[key])
    );
    if (
      latitude &&
      longitude &&
      latitude !== defaultValues.latitude &&
      longitude !== defaultValues.longitude
    ) {
      u.latitude = latitude;
      u.longitude = longitude;
    }
    if (u?.consultant?.id && u.consultant.id !== defaultValues?.consultant?.id) {
      u.consultant = u.consultant.id;
    } else if (u?.consultant) {
      delete u.consultant;
    }
    if (u?.files) {
      delete u.files;
    }
    if (u?.practices) {
      delete u.practices;
    }
    if (Object.keys(u).length > 0) {
      editUser(u, editElement.id)
        .then((res) => {
          setPopupSuccess(true);
          handlePopupClose();
        })
        .catch((err) => {
          setPopupErrorMessage("Qualcosa è andato storto, riprova tra qualche minuto.");
        });
    } else {
      setPopupSuccess(true);
      handlePopupClose();
    }
  }

  return (
    <>
      <Dialog open={popup} onClose={handlePopupClose} fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "30px" }}>
            <DialogTitle
              sx={{
                padding: "0",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              Modifica utente
              <IconButton onClick={handlePopupClose}>
                <Icon>close</Icon>
              </IconButton>
            </DialogTitle>
            {inputByRole()}
            <MDButton
              type="submit"
              variant="gradient"
              color="info"
              sx={{ cursor: "pointer", alignSelf: "center" }}
            >
              Salva
            </MDButton>
            {popupErrorMessage && (
              <MDBox px={5} pb={5}>
                {popupErrorMessage}
              </MDBox>
            )}
          </Box>
        </form>
      </Dialog>
      {popupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title="Utente modificato"
          success
          onConfirm={() => {
            setPopupSuccess(false);
            // window.location.reload();
            if (onConfirmEdit) {
              onConfirmEdit();
            }
          }}
          confirmBtnText="Ok"
          confirmBtnStyle={{ boxShadow: "none", color: "blue", marginLeft: "10px" }}
        >
          L&apos;utente è stato modificato con successo
        </SweetAlert>
      ) : null}
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
            {title}
          </MDTypography>
          {canEdit && (
            <IconButton
              onClick={() => {
                setPopup(true);
                setEditElement({ ...user });
              }}
            >
              <Icon fontSize="medium">edit</Icon>
            </IconButton>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDBox>{renderItems}</MDBox>
        </MDBox>
      </Card>
      <Dialog open={popupEmailSent} onClose={() => setPopupEmailSent(false)} fullWidth>
        <DialogTitle
          sx={{
            padding: "0",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <div />
          <IconButton onClick={() => setPopupEmailSent(false)}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        {popupEmailContent}
      </Dialog>
    </>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

export default ProfileInfoCard;
