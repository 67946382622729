/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import Box from "@mui/material/Box";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout/custom";

// Image
import bgImage from "assets/images/illustrations/grafica_testata.png";
import logoEuroansa from "assets/images/favicon-euroansa.png";
import logoGoogle from "assets/images/googleIcon.png";

import login from "api/auth/login";
import googleLoginApi from "api/auth/googleLogin";

function SignInCustom() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const performLogin = () => {
    // console.log("email ", email);
    // console.log("password ", password);
    const dataToSend = {
      email,
      password,
    };
    return login(dataToSend)
      .then((resp) => {
        // eslint-disable-next-line
        // console.log("login success", resp);
        setError("");
        window.location.href = "/";
      })
      .catch((err) => {
        // eslint-disable-next-line
        // console.log("error login", err);
        if (err?.message === "403 user role forbidden") {
          setError("Accesso non autorizzato: prova ad accedere dall'app AppRisparmio");
        } else {
          setError(
            "Ops, qualcosa è andato storto! Controlla i dati immessi o riprova fra qualche minuto"
          );
        }
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        const { user } = await googleLoginApi(code);
        const { name, lastName, province, region } = user;
        setError("");
        if (name && lastName && province && region) {
          window.location.href = "/";
        } else {
          window.location.href = "/signup";
        }
      } catch (err) {
        if (err?.response?.status === 403) {
          setError("Non possiedi le autorizzazioni necessarie per accedere");
          return;
        }
        setError("Si è verificato un errore, riprova!");
      }
    },
    flow: "auth-code",
  });

  return (
    <IllustrationLayout illustration={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Area riservata
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performLogin();
                  }
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                  // console.log("targetttttt", e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performLogin();
                  }
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  performLogin();
                }}
              >
                sign in
              </MDButton>
            </MDBox>
            {error && (
              <MDTypography display="block" variant="button" my={1} p="0 20px">
                {error}
              </MDTypography>
            )}

            <Box mt={4}>
              <MDTypography sx={{ textAlign: "center" }}>Accedi con</MDTypography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-evenly">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  onClick={googleLogin}
                  sx={{
                    border: "1px solid lightgray",
                    borderRadius: "100%",
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    component="img"
                    src={logoEuroansa}
                    alt="logo-euroansa"
                    width="35px"
                    height="35px"
                  />
                </Box>
                <MDTypography variant="body2">Euroansa</MDTypography>
              </Box>

              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  onClick={googleLogin}
                  sx={{
                    border: "1px solid lightgray",
                    borderRadius: "100%",
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    component="img"
                    src={logoGoogle}
                    alt="logo-google"
                    width="25px"
                    height="25px"
                  />
                </Box>
                <MDTypography variant="body2">Google</MDTypography>
              </Box>
            </Box>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="text"
                color="dark"
                fullWidth
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Hai dimenticato la tua password?
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </IllustrationLayout>
  );
}

export default SignInCustom;
