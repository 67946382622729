import axios from "axios";
import config from "config/config";

const { baseUrl } = config;

export default async function googleLogin(code) {
  const endpoint = `${baseUrl}/v1/auth/googlelogin`;
  const options = {
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": `${baseUrl}`,
      "Access-Control-Allow-Credentials": true,
    },
  };
  const {
    data: { tokens, user },
  } = await axios.post(endpoint, { code }, options);
  localStorage.setItem("refresh", JSON.stringify(tokens.refresh));
  localStorage.setItem("access", JSON.stringify(tokens.access));
  localStorage.setItem("userid", JSON.stringify(user.id));
  return { tokens, user };
}
