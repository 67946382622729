/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import getTokens from "api/getTokens";
import refreshTokens from "api/auth/refreshTokens";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import routesAdmin from "routesAdmin";
import routesConsultant from "routesConsultant";
import routesOperator from "routesOperator";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setUser } from "context";

import getUser from "api/users/getUser";

// Images
import brand from "assets/images/euroansa-logo.png";
import brandLittle from "assets/images/logotipo-euroansa.png";
import moment from "moment";

moment.locale("it");

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    user,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [isPageReady, setIsPageReady] = useState(false);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    const tokens = getTokens();
    // console.log("tokens", tokens.access, tokens.refresh);
    if (window.location.href.endsWith("/login")) {
      setIsPageReady(true);
    }
    if (!(pathname.startsWith("/verify-email") || pathname.startsWith("/reset-password"))) {
      if (window.location.href.endsWith("/login") && tokens.access && tokens.refresh) {
        // console.log("tokens", tokens);
        // console.log("entro qua");
        window.location.href = "/";
      } else if (!window.location.href.endsWith("/login") && !tokens.access && !tokens.refresh) {
        // console.log("entro qui");
        window.location.href = "/login";
      } else if (tokens.userid) {
        // refreshTokens();
        // console.log("userid", tokens.userid);
        refreshTokens()
          .then((responseJson) => {
            if (responseJson && responseJson.access && responseJson.refresh) {
              getUser(tokens.userid, responseJson.access)
                .then((res) => {
                  // console.log("tutto apposto", res);
                  if (!user || !user.id) {
                    setUser(dispatch, res);
                  }
                  setIsPageReady(true);
                })
                // eslint-disable-next-line
                .catch((err) => console.log("error", err));
            } else {
              localStorage.removeItem("refresh");
              localStorage.removeItem("access");
              localStorage.removeItem("userid");
              window.location.href = "/login";
            }
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log("no refresh tokens", err);
            window.location.href = "/login";
          });
        // getUser(tokens.userid)
        //   .then((res) => {
        //     // console.log("tutto apposto", res);
        //     if (!user || !user.id) {
        //       setUser(dispatch, res);
        //     }
        //     if ((!res || !res.mfaVerified || !tokens.mfa) && !window.location.href.endsWith("/mfa")) {
        //       window.location.href = "/mfa";
        //       // console.log("non dovrei entrare qui");
        //     }
        //   })
        //   .catch((err) => console.log("error", err));
      } else {
        // window.location.href = "/login";
      }
    } else {
      setIsPageReady(true);
    }
  }, [controller.user]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  let admin = false;

  if (user && user.role === "admin") {
    admin = true;
  }

  function getRoutesByRole() {
    if (!user) {
      return routes;
    }
    if (user.role === "admin") {
      return routesAdmin;
    }
    if (user.role === "consultant") {
      return routesConsultant;
    }
    if (user.role === "operator") {
      return routesOperator;
    }
    return routes;
  }

  function fixRoute(r) {
    const route = { ...r };
    if (!route.collapse) {
      if (user) {
        route.route = route.route.replace(
          "/dashboards/profile/:userId",
          `/dashboards/profile/${user.id}`
        );
        route.route = route.route.replace("/admin/profile/:userId", `/admin/profile/${user.id}`);
        route.route = route.route.replace(
          "/consultant/profile/:userId",
          `/consultant/profile/${user.id}`
        );
        route.route = route.route.replace(
          "/operator/profile/:userId",
          `/operator/profile/${user.id}`
        );
      }
      return route;
    }
    route.collapse = route.collapse.map(fixRoute);
    return route;
  }

  function displayPage() {
    return (
      <>
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandLittle={brandLittle}
              brandName="Comparabili"
              user={user}
              routes={getRoutesByRole().map(fixRoute)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(getRoutesByRole())}
          {user ? (
            <Route path="*" element={<Navigate to={`/dashboards/profile/${user.id}`} />} />
          ) : null}
        </Routes>
      </>
    );
  }

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {displayPage()}
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <>
      {isPageReady ? (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {displayPage()}
        </ThemeProvider>
      ) : (
        <div className="loader" />
      )}
    </>
  );
}
