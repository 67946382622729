import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl } = config;

/* *********************
  email
  password
********************** */

export default function verifyEmailToken(token) {
  const endpoint = `${baseUrl}/v1/auth/verify-email/?token=${encodeURIComponent(token)}`;
  // let access = "";
  // const tokens = getTokens();
  // if (tokens && tokens.access && tokens.access.token) {
  //   access = tokens.access.token;
  // } else {
  //   window.location.href = "/login";
  // }
  // const dataToSend = { token };
  // console.log("user dentro la chiamata", user);
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${baseUrl}`,
      "Access-Control-Allow-Credentials": true,
      // Authorization: `bearer ${access}`,
    },
    // body: JSON.stringify(dataToSend),
  };
  return new Promise((resolve, reject) => {
    fetch(endpoint, options)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
          reject();
        } else {
          resolve();
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
