import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController, setMiniSidenav, setUser } from "context";
import createUser from "api/users/createUser";
import getUsers from "api/users/getAllUser";
import randomPassword from "utils/randomPassword";
import SweetAlert from "react-bootstrap-sweetalert";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Text from "components/custom/form/Text";
import AutoComplete from "components/custom/form/AutoComplete";
import inputs from "components/custom/form/inputs";
import Location from "components/custom/form/Location";

function ParametricAddUser({ userRole }) {
  const [controller] = useMaterialUIController();
  const [errorMessage, setErrorMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [consultantList, setConsultantList] = useState([]);
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [createdUserId, setCreatedUserId] = useState("");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {},
  });

  const city = watch("city");
  const address = watch("address");

  useEffect(async () => {
    if (userRole === "user") {
      setConsultantList(
        (await getUsers({ role: "consultant", limit: 100000 })).results
          .map((u) => ({
            id: u.id,
            label: `${u.name || "null"} ${u.lastName || "null"}`,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    } else {
      setConsultantList([]);
    }
    reset();
  }, [userRole]);

  function inputByRole() {
    switch (userRole) {
      case "user":
        return (
          <>
            <Text control={control} {...inputs.name} />
            <Text control={control} {...inputs.lastName} />
            <Text control={control} {...inputs.email} />
            <Text control={control} {...inputs.phone} />
            <Text control={control} {...inputs.fiscalCode} />
            <Text
              control={control}
              {...inputs.city}
              validation={{ required: { value: true, message: "Inserisci la città" } }}
            />
            <Text
              control={control}
              {...inputs.address}
              validation={{ required: { value: true, message: "Inserisci l' indirizzo" } }}
            />
            <Text
              control={control}
              {...inputs.streetNumber}
              validation={{ required: { value: true, message: "Inserisci il civico" } }}
            />
            <Location
              city={city}
              address={address}
              onChangeLocation={(loc) => {
                setLatitude(loc.latitude);
                setLongitude(loc.longitude);
                if (loc.city) {
                  setValue("city", loc.city);
                }
                if (loc.address) {
                  setValue("address", loc.address);
                }
              }}
            />
            {controller.user.role === "admin" && (
              <AutoComplete
                control={control}
                name="consultant"
                label="Consulente"
                options={consultantList}
              />
            )}
          </>
        );
      case "consultant":
        return (
          <>
            <Text control={control} {...inputs.name} />
            <Text control={control} {...inputs.lastName} />
            <Text control={control} {...inputs.email} />
            <Text control={control} {...inputs.phone} />
            {/* <Text control={control} {...inputs.mobilePhone} /> */}
            <Text control={control} {...inputs.city} />
          </>
        );
      default:
        return (
          <>
            <Text control={control} {...inputs.name} />
            <Text control={control} {...inputs.lastName} />
            <Text control={control} {...inputs.email} />
          </>
        );
    }
  }

  function onSubmit(data) {
    const user = Object.fromEntries(Object.entries(data).filter(([key, value]) => !!value));
    if (!user.role) {
      user.role = userRole;
    }
    if (!user.password) {
      user.password = randomPassword();
    }
    if (latitude && longitude) {
      user.latitude = latitude;
      user.longitude = longitude;
    }
    if (user?.consultant?.id) {
      user.consultant = user.consultant.id;
    } else if (controller?.user?.role === "consultant" && controller?.user?.id) {
      user.consultant = controller.user.id;
    }
    setCreatedUserId("");
    createUser(user)
      .then((res) => {
        // eslint-disable-next-line
        if (res) {
          setErrorMessage("");
          setPopup(true);
          if (res?.data?.id) {
            setCreatedUserId(res.data.id);
          }
        } else {
          setErrorMessage(
            "Qualcosa è andato storto, controlla che la mail non sia già stata registrata"
          );
        }
      })
      .catch((err) => {
        // console.log("errore creazione utente", err);
        if (err?.response?.status === 405) {
          setErrorMessage(
            "Esiste già un cliente con questa email assegnato ad un altro consulente. Contatta il backoffice per informazioni"
          );
        } else {
          setErrorMessage(
            "Qualcosa è andato storto, controlla che la mail non sia già stata registrata"
          );
        }
      });
  }

  return (
    <DashboardLayout>
      {popup && (
        <SweetAlert
          style={{ display: "block" }}
          title="Account creato"
          success
          onConfirm={() => {
            setPopup(false);
            if (createdUserId) {
              navigate(`/dashboards/profile/${createdUserId}`);
            } else {
              navigate(`/${controller.user.role}/${userRole}list`);
            }
          }}
          confirmBtnText="Ok"
          confirmBtnStyle={{ boxShadow: "none", color: "blue" }}
        >
          L&apos;utente è stato creato con successo
        </SweetAlert>
      )}
      <DashboardNavbar />
      <Card>
        <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "30px",
                alignItems: "center",
                width: "max(50%, 600px)",
              }}
              p={5}
            >
              {inputByRole()}
              <MDButton type="submit" variant="gradient" color="info">
                AGGIUNGI
              </MDButton>
              {errorMessage && (
                <MDBox px={5} pb={5}>
                  {errorMessage}
                </MDBox>
              )}
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default ParametricAddUser;
