import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import MDBox from "components/MDBox";

export default function CustomDate({
  control,
  name,
  label,
  type,
  validation = {},
  defaultValue = "",
  readOnly = false,
  DatePickerProps = {},
  inputProps = {},
  BoxProps = {},
}) {
  return (
    <MDBox {...BoxProps}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={validation}
        render={({ field, fieldState, formState }) => (
          <DatePicker
            {...field}
            label={label}
            type="date"
            inputFormat="DD/MM/YYYY"
            onChange={(momentObj) => {
              if (!readOnly) {
                field.onChange(momentObj);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!formState.errors[name]}
                helperText={formState.errors[name] && formState.errors[name].message}
              />
            )}
            inputProps={{ readOnly, ...inputProps }}
            {...DatePickerProps}
          />
        )}
      />
    </MDBox>
  );
}
