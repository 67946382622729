import Box from "@mui/material/Box";
import MDButton from "components/MDButton";
import MDDropzone from "components/MDDropzone";

import config from "config/config";

export default function CustomDropzone({ loadFile, onSuccess, onError, onUploadStart = null }) {
  let myDropzone;
  let queued;
  const uploadPromises = [];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", gap: "15px" }}>
      <MDDropzone
        options={{
          addRemoveLinks: true,
          maxFilesize: 15 * 1024 * 1024,
          parallelUploads: 10,
          maxFiles: 10,
          autoProcessQueue: false,
          init() {
            myDropzone = this;
          },
          // eslint-disable-next-line
          url: (file) => {
            // console.log("URL");
            return `${config.baseUrl}/v1/ping/pong`;
          },
          // eslint-disable-next-line
          // complete: (file) => {
          //   console.log("complete", file);
          //   // eslint-disable-next-line
          //   if (file._removeLink) {
          //     // eslint-disable-next-line
          //     file._removeLink.innerHTML = this.options.dictRemoveFile;
          //   }
          //   if (file.previewElement) {
          //     return file.previewElement.classList.add("dz-complete");
          //   }
          // },
          // eslint-disable-next-line
          success: (file) => {
            uploadPromises.push(loadFile(file));
            if (uploadPromises.length >= queued) {
              Promise.all(uploadPromises).then(onSuccess).catch(onError);
            }
          },
          error: (file) => {
            uploadPromises.push(loadFile(file));
            if (uploadPromises.length >= queued) {
              Promise.all(uploadPromises).then(onSuccess).catch(onError);
            }
          },
          // removedfile: (file) => {
          //   console.log("ho cancellato", file);
          //   if (file.previewElement != null && file.previewElement.parentNode != null) {
          //     file.previewElement.parentNode.removeChild(file.previewElement);
          //   }
          //   // eslint-disable-next-line
          //   return this._updateMaxFilesReachedClass();
          // },
        }}
      />
      <MDButton
        variant="gradient"
        color="info"
        sx={{ cursor: "pointer", alignSelf: "center" }}
        onClick={() => {
          queued = myDropzone.getQueuedFiles().length;
          myDropzone.processQueue();
          if (onUploadStart) {
            onUploadStart();
          }
        }}
      >
        Salva
      </MDButton>
    </Box>
  );
}
