import { useState, useEffect } from "react";

import { useMaterialUIController } from "context";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "layouts/dashboards/customerProfile/ProfileInfoCard";
import Card from "@mui/material/Card";

import OpenMap from "components/custom/geocoding/OpenMap";

import config from "config/config";
import getAllUser from "api/users/getAllUser";
import downloadFile from "api/file/downloadFile";
import deleteFile from "api/file/deleteFile";
import CustomList from "components/custom/CustomList";
import DropzoneDialog from "components/custom/DropzoneDialog";
import escapeRegex from "utils/escapeRegex";
import {
  Autocomplete,
  Icon,
  IconButton,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

function ShowLocation() {
  const [controller] = useMaterialUIController();
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [cityFilter, setCityFilter] = useState("");
  const [addressFilter, setAddressFilter] = useState("");
  const [mapFilter, setMapFilter] = useState({});
  const [filterShowUsers, setFilterShowUsers] = useState(controller.user.role !== "operator");
  const [filterShowImmo, setFilterShowImmo] = useState(true);

  useEffect(() => {
    const params = {
      role: "user",
      populate: "consultant",
      limit: 1000,
    };
    if (controller.user.role === "consultant") {
      params.consultant = controller.user.id;
    }
    if (controller.user.role === "operator") {
      params.region = "lazio|toscana";
    }
    getAllUser(params)
      .then((res) => {
        const usersList = [];
        const filesList = [];
        if (res.results === 0) {
          throw new Error("No user for this consultant");
        }
        res.results.forEach((customer) => {
          if (customer.latitude && customer.longitude) {
            usersList.push(customer);
          }
          if (customer.files && customer.files.length > 0) {
            customer.files
              .filter((file) => file.latitude && file.longitude)
              .forEach((file) => {
                filesList.push({
                  ...file,
                  userId: customer.id,
                  customerName: customer.name,
                  customerLastName: customer.lastName,
                });
              });
          }
        });
        setUsers(usersList);
        setFiles(filesList);
        setData([...usersList, ...filesList]);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    setData([...(filterShowUsers ? users : []), ...(filterShowImmo ? files : [])]);
    setCityFilter("");
    setAddressFilter("");
    setMapFilter({});
  }, [filterShowUsers, filterShowImmo]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", boxShadow: false }}>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h5" fontWeight="medium">
            Mappa
          </MDTypography>
        </MDBox>
        <MDBox pt={2} px={2}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={controller.user.role !== "operator"}
                  color="warning"
                  disabled={controller.user.role === "operator"}
                />
              }
              onChange={(event) => setFilterShowUsers(event.target.checked)}
              label="Mostra utenti"
            />
            <FormControlLabel
              control={<Switch defaultChecked color="default" />}
              onChange={(event) => setFilterShowImmo(event.target.checked)}
              label="Mostra immobili"
            />
          </FormGroup>
        </MDBox>
        <MDBox
          pt={2}
          px={2}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          sx={{
            "& .MuiAutocomplete-option": { textTransform: "capitalize" },
          }}
        >
          <Autocomplete
            value={cityFilter}
            disablePortal
            options={[
              ...new Set(data.filter((value) => !!value.city).map((v) => v.city.toLowerCase())),
            ]}
            sx={{ flex: "1 1 auto" }}
            onChange={(event, value) => {
              if (value) {
                setCityFilter(value);
              } else {
                setCityFilter("");
                setAddressFilter("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Città"
                inputProps={{
                  ...params.inputProps,
                  style: { ...params.inputProps.style, textTransform: "capitalize" },
                }}
              />
            )}
          />
          <Autocomplete
            value={addressFilter}
            disablePortal
            options={[
              ...new Set(
                data
                  .filter(
                    (v) =>
                      !!v.city &&
                      !!v.address &&
                      (!cityFilter || v.city.toLowerCase() === cityFilter)
                  )
                  .map((v) => v.address.toLowerCase())
              ),
            ]}
            sx={{ flex: "2 1 auto" }}
            onChange={(event, value) => {
              if (value) {
                setAddressFilter(value);
              } else {
                setAddressFilter("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Indirizzo"
                inputProps={{
                  ...params.inputProps,
                  style: { ...params.inputProps.style, textTransform: "capitalize" },
                }}
              />
            )}
          />
          <IconButton
            onClick={() => {
              setMapFilter({ city: cityFilter, address: addressFilter });
            }}
          >
            <Icon>search</Icon>
          </IconButton>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={12} xl={12}>
              <OpenMap
                files={filterShowImmo ? files : []}
                users={filterShowUsers ? users : []}
                // files={files}
                // users={users}
                filter={mapFilter}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default ShowLocation;
