import config from "config/config";
import getTokens from "api/getTokens";
import axios from "axios";

const { baseUrl } = config;

export default async function getPracticesByUserPaginate({ userId, ...rest }) {
  const endpoint = `${baseUrl}/v1/practice/paginate`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${baseUrl}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
    params: { userId, ...rest },
  };
  return axios.get(endpoint, options);
}
