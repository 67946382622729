import { useState } from "react";
import { useForm } from "react-hook-form";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import Location from "components/custom/form/Location";
import Text from "components/custom/form/Text";
import inputs from "components/custom/form/inputs";

export default function AddLocation({
  action,
  cancel,
  defaultValues = {},
  cancelButtonLabel = "annulla",
  submitButtonLabel = "salva",
}) {
  const [location, setLocation] = useState(
    defaultValues && defaultValues.latitude && defaultValues.longitude
      ? {
          latitude: defaultValues.latitude,
          longitude: defaultValues.longitude,
          city: defaultValues.city,
          address: defaultValues.address,
          streetNumber: defaultValues.streetNumber,
        }
      : null
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: Object.fromEntries(
      Object.entries(defaultValues || {}).filter(([k, v]) =>
        ["latitude", "longitude", "city", "address", "streetNumber"].includes(k)
      )
    ),
  });

  const city = watch("city");
  const address = watch("address");

  function onSubmit(data) {
    action({ ...data, ...location });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MDBox
        sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "32px" }}
      >
        <Text
          control={control}
          {...inputs.city}
          validation={{ required: { value: true, message: "Inserisci la città" } }}
          TextFieldProps={{ sx: { mt: "16px" } }}
        />
        <Text
          control={control}
          {...inputs.address}
          validation={{ required: { value: true, message: "Inserisci l' indirizzo" } }}
        />
        <Text control={control} {...inputs.streetNumber} />
        <Location
          city={city}
          address={address}
          onChangeLocation={(params) => {
            setLocation(params);
            setValue("city", params.city || "");
            setValue("address", params.address || "");
          }}
          defaultValues={defaultValues}
        />
        <MDBox sx={{ alignSelf: "stretch", display: "flex", justifyContent: "space-around" }}>
          {cancel && (
            <MDButton variant="text" color="info" onClick={cancel}>
              {cancelButtonLabel}
            </MDButton>
          )}
          <MDButton type="submit" variant="gradient" color="info">
            {submitButtonLabel}
          </MDButton>
        </MDBox>
      </MDBox>
    </form>
  );
}
