import { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDTypography from "components/MDTypography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import SweetAlert from "react-bootstrap-sweetalert";

import MDButton from "components/MDButton";
import CustomDropzone from "components/custom/CustomDropzone";
import AddLocation from "components/custom/geocoding/AddLocation";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import uploadFile from "api/file/uploadFile";

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => reject();
  });
}

function DropboxDialog({
  section,
  userId,
  selectOptions,
  userLocation = {},
  onConfirmPopup,
  promptSecondHouse,
}) {
  const [open, setOpen] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [popupErrorMessage, setPopupErrorMessage] = useState("");
  const [subCategory, setSubCategory] = useState(selectOptions[0].value);
  const [secondHouse, setSecondHouse] = useState(false);
  const [step, setStep] = useState(0);
  const [location, setLocation] = useState(null);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setStep(0);
  }

  function loadFile(file) {
    return new Promise((resolve, reject) => {
      readFile(file)
        .then((data) => {
          const [dataApp, datas] = data.split(";", 2);
          const [enc, base64] = datas.split(",", 2);
          const opt = {
            filename: "",
            type: "",
            userId,
            category: section,
            subCategory,
          };
          if (secondHouse) {
            opt.secondHouse = true;
          }
          if (file.upload.filename) {
            opt.filename = file.upload.filename;
            const split = file.upload.filename.split(".");
            if (split.length > 1) {
              opt.type = split[split.length - 1];
            }
          }
          if (location) {
            Object.entries(location)
              .filter(([k, v]) => !!v)
              .forEach(([k, v]) => {
                opt[k] = v;
              });
          }
          if (base64) {
            // TODO gestire il multi caricamento contemporaneo o toglierlo
            resolve(uploadFile(base64, opt));
            /* .then((res) => {
              // console.log("ho fatto cose", res);
              setPopupSuccess(true);
              handleClose();
            })
            .catch((err) => {
              setPopupErrorMessage("Il caricamento del file non è riuscito!");
              handleClose();
            }); */
          } else {
            throw new Error("No base64 body");
          }
        })
        .catch((err) => reject(err));
    });
  }

  const [uploadingFile, setUploadingFile] = useState(false);

  function onSuccess() {
    setUploadingFile(false);
    setPopupSuccess(true);
    handleClose();
  }

  function onError(err) {
    setUploadingFile(false);
    setPopupErrorMessage("Il caricamento del file non è riuscito!");
    // handleClose();
  }

  return (
    <>
      <MDButton variant="text" size="small" color="info" onClick={handleOpen}>
        Carica un documento
      </MDButton>
      <Backdrop
        sx={{ color: "#fff", zIndex: "99999999" }}
        open={uploadingFile}
        // onClick={handleClose}
      >
        <CircularProgress color="info" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <Box style={{ padding: "16px" }}>
          <DialogTitle
            sx={{
              padding: "0",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            Carica un documento
            <IconButton onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </DialogTitle>
          {step === 0 && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "32px",
              }}
            >
              <MDTypography variant="subtitle2">
                Seleziona il tipo di documento da caricare:
              </MDTypography>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Tipo documento</InputLabel>
                <Select
                  value={subCategory}
                  label={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  {selectOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {promptSecondHouse && (
                <FormControlLabel
                  control={<Switch color="warning" checked={secondHouse} />}
                  onChange={(event) => setSecondHouse(event.target.checked)}
                  label="Seconda casa"
                />
              )}
              <MDButton
                sx={{ cursor: "pointer", alignSelf: "center" }}
                variant="gradient"
                color="info"
                onClick={() => {
                  setStep(step + 1);
                  setLocation(null);
                }}
              >
                Avanti
              </MDButton>
            </Box>
          )}
          {step === 1 && (
            <AddLocation
              cancelButtonLabel="salta"
              submitButtonLabel="avanti"
              cancel={() => {
                setStep(step + 1);
                setLocation(null);
              }}
              action={(loc) => {
                setStep(step + 1);
                setLocation(loc);
              }}
              defaultValues={userLocation}
            />
          )}
          {step === 2 && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "32px",
              }}
            >
              <MDTypography variant="subtitle2">
                Clicca sul box o trascina un file al suo interno:
              </MDTypography>
              <CustomDropzone
                loadFile={loadFile}
                onSuccess={onSuccess}
                onError={onError}
                onUploadStart={() => setUploadingFile(true)}
              />
            </Box>
          )}
          {popupErrorMessage ? <span style={{ color: "red" }}>{popupErrorMessage}</span> : null}
        </Box>
      </Dialog>
      {popupSuccess ? (
        <SweetAlert
          style={{ display: "block" }}
          title="Caricamento completato"
          success
          onConfirm={() => {
            setPopupSuccess(false);
            // window.location.reload();
            if (onConfirmPopup) {
              onConfirmPopup();
            }
          }}
          confirmBtnText="Ok"
          confirmBtnStyle={{ boxShadow: "none", color: "blue", marginLeft: "10px" }}
        >
          Il documento è stato caricato con successo!
        </SweetAlert>
      ) : null}
    </>
  );
}

export default DropboxDialog;
