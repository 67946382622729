import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { forward as forwardGeo, reverse as reverseGeo } from "api/geocoding";

export default function Location({ city, address, onChangeLocation, defaultValues }) {
  const [location, setLocation] = useState(
    defaultValues && defaultValues.longitude && defaultValues.latitude
      ? `${defaultValues.latitude}, ${defaultValues.longitude}`
      : ""
  );
  const [locationError, setLocationError] = useState("");

  function updateLocation() {
    if (city && address) {
      forwardGeo(city, address)
        .then((res) => {
          if (!res || res.length === 0 || !res[0].latitude || !res[0].longitude) {
            setLocationError("La ricerca non ha prodotto risultati");
          } else {
            const result = res[0];
            const params = {};
            if (result.city) {
              params.city = result.city;
            } else if (result.town) {
              params.city = result.town;
            } else if (result.village) {
              params.city = result.village;
            } else {
              params.city = city;
            }
            if (result.road) {
              params.address = result.road;
            }
            if (result.latitude) {
              params.latitude = result.latitude;
            }
            if (result.longitude) {
              params.longitude = result.longitude;
            }
            if (result.postalCode) {
              params.postalCode = result.postcode;
            }
            if (result.county) {
              params.county = result.county;
            }
            setLocationError("");
            onChangeLocation(params);
            setLocation(`${result.latitude}, ${result.longitude}`);
          }
        })
        .catch((err) => {
          setLocationError("La ricerca non ha prodotto risultati");
          // console.log(err);
        });
    } else {
      setLocationError("Inserisci la città e l' indirizzo");
    }
  }

  return (
    <TextField
      type="text"
      label="Posizione"
      fullWidth
      value={location}
      error={!!locationError}
      helperText={locationError}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="search-location" onClick={updateLocation} edge="end">
              <Icon>search</Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
