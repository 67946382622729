export default function getTokens() {
  const refresh = JSON.parse(localStorage.getItem("refresh"));
  const access = JSON.parse(localStorage.getItem("access"));
  const userid = JSON.parse(localStorage.getItem("userid"));
  const mfa = JSON.parse(localStorage.getItem("mfa"));
  // console.log("refresh", refresh);
  // console.log("access", access);
  return { refresh, access, userid, mfa };
}

export function getTokensPromise() {
  return Promise.resolve().then(() => {
    const refresh = JSON.parse(localStorage.getItem("refresh"));
    const access = JSON.parse(localStorage.getItem("access"));
    return { refresh, access };
  });
}
