const configuration = {
  development: {
    baseUrl: "http://localhost:3008",
    // baseUrl: "http://192.168.1.8:3005",
    // baseUrl: "http://192.168.43.78:3005",
    blobBaseUrl: "https://foodiestrip.blog",
    imageRoot: "http://localhost:3010/",
    cdn: "https://cdn.foodiestrip.com/",
    login: "http://localhost:3000/login",
    clientUrl: "https://localhost:3000",
    googleLoginClientId: "524363856904-81pd3b2du6uvnmejm2s8as9dctpfc9dj.apps.googleusercontent.com",
  },
  staging: {
    baseUrl: "http://3.64.193.23:3008",
    blobBaseUrl: "https://foodiestrip.blog",
    imageRoot: "https://cdn.foodiestrip.com/",
    cdn: "https://cdn.foodiestrip.com/",
    login: "https://login.foodiestrip.com/",
    clientUrl: "http://18.159.130.75",
    googleLoginClientId: "524363856904-81pd3b2du6uvnmejm2s8as9dctpfc9dj.apps.googleusercontent.com",
  },
  production: {
    baseUrl: "https://comparabili-api.euroansa.it",
    blobBaseUrl: "https://comparabili-api.euroansa.it",
    imageRoot: "https://comparabili-api.euroansa.it/",
    cdn: "https://comparabili-api.euroansa.it/",
    login: "https://comparabili.euroansa.it/login",
    clientUrl: "https://comparabili.euroansa.it",
    googleLoginClientId: "524363856904-81pd3b2du6uvnmejm2s8as9dctpfc9dj.apps.googleusercontent.com",
  },
};

export default configuration;
