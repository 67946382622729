import downloadFile from "api/file/downloadFile";

export default function downloadDoc({ key, filename }) {
  const split = key.split("/");
  const name = filename || split[split.length - 1];
  downloadFile(key)
    .then((res) => res.blob())
    .then((blob) => {
      const fileURL = URL.createObjectURL(blob);
      // Open the URL on new Window
      /* const pdfWindow = window.open();
        pdfWindow.location.href = fileURL; */
      // IF WE WANT TO DOWNLOAD THE FILE INSTEAD OF OPENING IT IN NEW TAB
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = fileURL;
      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    })
    .catch((err) => {
      // eslint-disable-next-line no-alert
      window.alert("Si è verificato un errore durante il download del file");
    });
}
