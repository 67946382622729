import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl } = config;

export default function associateAppRisparmioUser({ userId, arUserId, accessToken = null }) {
  const endpoint = `${baseUrl}/v1/users/associate`;
  let access = "";
  if (accessToken && accessToken.token) {
    access = accessToken.token;
  } else {
    const tokens = getTokens();
    if (tokens && tokens.access && tokens.access.token) {
      access = tokens.access.token;
    } else {
      window.location.href = "/login";
    }
  }
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${baseUrl}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
    body: JSON.stringify({ userId, arUserId }),
  };
  return fetch(endpoint, options).then((response) => {
    // console.log('login response', response);
    if (response.status === 403) {
      throw new Error("L'account è già associato");
    }
    if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
      throw new Error(
        "C'è stato un errore nell'associazione degli account, ti invitiamo a riprovare piu tardi."
      );
    }
    return response.json();
  });
}
