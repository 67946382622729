/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import verifyEmailToken from "api/auth/verifyEmailToken";

function Basic() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    verifyEmailToken(token)
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      })
      .catch(() => {
        setSuccess(false);
      });
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Verifica email
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            {success ? (
              <MDTypography display="block" variant="button" my={1} p="0 20px">
                Email verificata! Stai per essere reindirizzato alla pagina di login!
              </MDTypography>
            ) : (
              <MDTypography display="block" variant="button" my={1} p="0 20px">
                Si è verificato un errore nella verifica dell&apos; email!
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
