import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";

import { useMaterialUIController } from "context";
import { useParams } from "react-router-dom";
import StatusPractices from "components/custom/statusPractices";
import { useEffect, useState } from "react";
import getUser from "api/users/getUser";

export default function PracticeStatus() {
  const [controller] = useMaterialUIController();
  const { userId } = useParams();
  const [fullName, setFullName] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [userObject, setUserObject] = useState(null);

  useEffect(() => {
    if (userId) {
      getUser(userId)
        .then((user) => {
          const name = user.name && user.lastName ? `${user.name} ${user.lastName}` : user.email;
          setFullName(name);
          setUserObject(user);
          setLoaded(true);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [userId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {["admin", "consultant"].includes(controller?.user?.role) && loaded ? (
        <Card sx={{ height: "100%", boxShadow: false }}>
          <MDBox pt={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h5" fontWeight="medium">
              Stato pratiche di{" "}
              <a
                href={`/dashboards/profile/${encodeURIComponent(userId)}`}
                target="_blank"
                rel="noreferrer"
                className="link-fullname"
              >
                {fullName}
              </a>
            </MDTypography>
          </MDBox>
          <MDBox p={2} sx={{ overflowX: "auto" }}>
            <StatusPractices userToView={userObject} />
          </MDBox>
        </Card>
      ) : (
        <Card sx={{ height: "100%", boxShadow: false, p: "40px" }}>
          <span>Ops! Sembra tu non abbia i permessi per visualizzare questa pagina</span>
        </Card>
      )}
    </DashboardLayout>
  );
}
