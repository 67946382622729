import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

export default function Text({
  control,
  name,
  label,
  type,
  validation = {},
  defaultValue = "",
  TextFieldProps = {},
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={validation}
      render={({ field, fieldState, formState }) => (
        <TextField
          {...field}
          fullWidth
          variant="outlined"
          label={`${label}${validation?.required?.value ? " *" : ""}`}
          type={type}
          error={!!formState.errors[name]}
          helperText={formState.errors[name] && formState.errors[name].message}
          {...TextFieldProps}
        />
      )}
    />
  );
}
