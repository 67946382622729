import { useState, useCallback, useMemo } from "react";
import MDTypography from "components/MDTypography";
import StepChoice from "components/custom/statusPractices/StepChoice";
import PRACTICE_STEP_OPTIONS from "components/custom/statusPractices/practiceStepOptions";

export default function Practice({ value: practiceStatus, onChange }) {
  const [openPractice, setOpenPractice] = useState(Object.keys(PRACTICE_STEP_OPTIONS)[0] || null);
  const onChangeOpenPractice = useCallback(
    (event) => {
      if (event.currentTarget.checked) {
        setOpenPractice(event.currentTarget.name);
      }
    },
    [setOpenPractice]
  );
  const onChangeStepChoice = useCallback(
    (value, stepName) => {
      if (onChange) {
        onChange({ ...practiceStatus, [stepName]: value });
      }
    },
    [practiceStatus, onChange]
  );

  const practiceStepOptions = useMemo(() => {
    if (practiceStatus) {
      return Object.fromEntries(
        Object.entries(PRACTICE_STEP_OPTIONS).map(([stepName, subStepArray]) => [
          stepName,
          subStepArray.map(({ label, value }) => ({
            value,
            label: practiceStatus[stepName][value]?.status ? `${label} (completato)` : label,
          })),
        ])
      );
    }
    return PRACTICE_STEP_OPTIONS;
  }, [PRACTICE_STEP_OPTIONS, practiceStatus]);

  if (!practiceStatus) {
    return null;
  }

  return (
    <>
      <div className="radio-inputs" style={{ marginBottom: "2rem" }}>
        {Object.keys(PRACTICE_STEP_OPTIONS).map((stepName) => (
          <label htmlFor={`${stepName}-label`} key={`${stepName}-label`} className="radio">
            <input
              id={`${stepName}-label`}
              type="radio"
              name={stepName}
              checked={openPractice === stepName}
              onChange={onChangeOpenPractice}
            />
            <span className="name">{stepName}</span>
          </label>
        ))}
      </div>
      {Object.keys(PRACTICE_STEP_OPTIONS)
        .filter((stepName) => stepName === openPractice)
        .map((stepName) => (
          <div
            key={`${stepName}-container`}
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {/* <MDTypography variant="body1">{stepName}</MDTypography> */}
            <StepChoice
              value={practiceStatus[stepName]}
              onChange={onChangeStepChoice}
              stepName={stepName}
              stepOptions={practiceStepOptions}
            />
          </div>
        ))}
    </>
  );
}
