import { useState, useCallback } from "react";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import StepDetails from "components/custom/statusPractices/StepDetails";
import { Divider } from "@mui/material";

export default function StepChoice({ value, stepName, stepOptions, onChange }) {
  const [selectedStep, setSelectedStep] = useState(stepOptions[stepName][0].value);

  const detailsOnChange = useCallback(
    (v) => {
      if (onChange) {
        onChange({ ...value, [selectedStep]: { ...v, lastUpdate: moment() } }, stepName);
      }
    },
    [onChange, value, stepName, selectedStep]
  );
  const selectOnChange = useCallback((e) => setSelectedStep(e.target.value), [setSelectedStep]);
  const onChangeNote = useCallback(
    (event) => {
      if (onChange) {
        onChange({ ...value, note: event.target.value }, stepName);
      }
    },
    [onChange, value, stepName]
  );

  if (!value) {
    return null;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "0.75rem", lineHeight: "1.5", margin: 0, padding: 0 }}>
          Descrizione stato pratica:
        </span>
        <TextareaAutosize
          placeholder="In questa fase si è provveduto a..."
          minRows={5}
          value={value.note}
          onChange={onChangeNote}
          style={{ padding: "0.5rem" }}
        />
      </div>
      <Divider />
      <FormControl
        fullWidth
        sx={{
          "& .MuiSelect-select.MuiInputBase-input": {
            padding: "0.75rem!important",
          },
        }}
      >
        <InputLabel>Seleziona attività</InputLabel>
        <Select label="Seleziona attività" value={selectedStep} onChange={selectOnChange}>
          {stepOptions[stepName].map((v, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={`${v}_${index}`} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <StepDetails value={value[selectedStep]} onChange={detailsOnChange} />
    </div>
  );
}
