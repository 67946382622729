export default {
  "Delibera mutuo": [
    {
      label: "in attesa documentazione",
      value: "in attesa documentazione",
    },
    { label: "incarico completato", value: "incarico completato" },
    { label: "pratica inserita in banca", value: "pratica inserita in banca" },
    {
      label: "conferma banca presa in carico pratica",
      value: "conferma banca presa in carico pratica",
    },
    { label: "pratica in processazione bancaria", value: "pratica in processazione bancaria" },
    { label: "pratica in fase di delibera", value: "pratica in fase di delibera" },
    { label: "delibera avvenuta", value: "delibera avvenuta" },
    { label: "apertura conto corrente", value: "apertura conto corrente" },
  ],
  "Perizia bancaria": [
    { label: "pagamento perizia", value: "pagamento perizia" },
    { label: "incarico perito (inviato)", value: "incarico perito (inviato)" },
    {
      label: "contatto da parte del perito (per fissazione sopralluogo)",
      value: "contatto da parte del perito (per fissazione sopralluogo)",
    },
    { label: "sopralluogo (perizia)", value: "sopralluogo (perizia)" },
    { label: "perizia in lavorazione", value: "perizia in lavorazione" },
    { label: "perizia validata", value: "perizia validata" },
  ],
  "Certificazione Documentale": [
    {
      label: "verifica documentazione per certificazione documentale (genio diligence)",
      value: "verifica documentazione per certificazione documentale (genio diligence)",
    },
    { label: "pagamento genio diligence", value: "pagamento genio diligence" },
    { label: "genio diligence completata", value: "genio diligence completata" },
  ],
  "Controllo e validazione notarile/bancaria": [
    {
      label: "verifica documentazione per Relazione Notarile Preliminare",
      value: "verifica documentazione per Relazione Notarile Preliminare",
    },
    {
      label: "incarico notaio e richiesta Relazione Notarile Preliminare",
      value: "incarico notaio e richiesta Relazione Notarile Preliminare",
    },
    { label: "pratica notarile in lavorazione", value: "pratica notarile in lavorazione" },
    {
      label: "trasmissione Relazione Notarile Preliminare alla banca",
      value: "trasmissione Relazione Notarile Preliminare alla banca",
    },
    {
      label: "verifica e validazione ufficio tecnico legale della banca",
      value: "verifica e validazione ufficio tecnico legale della banca",
    },
  ],
  "Atto notarile": [
    { label: "individuazione data di stipula", value: "individuazione data di stipula" },
    {
      label: "chiamata d'atto per chiusura pratica",
      value: "chiamata d'atto per chiusura pratica",
    },
    { label: "in rogito notarile (data fissata)", value: "in rogito notarile (data fissata)" },
  ],
};
