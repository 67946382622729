// @mui material components
import { useState, useEffect } from "react";
import { useMaterialUIController } from "context";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

// Material Dashboard 2 PRO React components
import getArticle from "api/news/getArticle";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import config from "config/config";

const { imageRoot, baseUrl } = config;

function Main() {
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl && currentUrl.includes("article?")) {
      const idToFind = currentUrl.split("article?")[1];
      getArticle(idToFind)
        .then((res) => {
          if (res && res.id) {
            setArticle(res);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("error while getting the article", err);
        });
    }
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {article && article.id ? (
          <div>
            {article.imageUri && article.title ? (
              <img
                className="news-details-image"
                src={imageRoot + article.imageUri}
                alt={article.title}
              />
            ) : null}
            {article.title ? (
              <h2 style={{ fontSize: "26px", marginTop: "20px" }}>{article.title}</h2>
            ) : null}
            {article.subtitle ? (
              <h3 style={{ fontSize: "20px", marginTop: "20px" }}>{article.subtitle}</h3>
            ) : null}
            {article.htmlContent ? (
              <p style={{ fontSize: "18px", marginTop: "20px" }}>
                {ReactHtmlParser(
                  article.htmlContent.replaceAll("&lt;", "<").replaceAll("&gt;", ">")
                )}
              </p>
            ) : null}
            {article.date ? (
              <div style={{ marginTop: "30px" }}>{`${moment(article.date).format("DD")}-${moment(
                article.date
              ).format("MM")}-${moment(article.date).format("YYYY")}`}</div>
            ) : null}
          </div>
        ) : null}
      </DashboardLayout>
    </>
  );
}

export default Main;
