import { useState, useCallback, useEffect } from "react";
import { useMaterialUIController } from "context";
import moment from "moment";
import MDButton from "components/MDButton";
import CustomList from "components/custom/CustomList";
import Practice from "components/custom/statusPractices/Practice";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";
import PRACTICE_STEP_OPTIONS from "components/custom/statusPractices/practiceStepOptions";
import getPracticesByUserPaginate from "api/practice/getPracticesByUserPaginate";
import addPractice from "api/practice/addPractice";
import editPractice from "api/practice/editPractice";

const PRACTICE_FIELDS = [
  { name: "name", label: "Nome" },
  // { name: "updatedAt", label: "Ultima modifica" },
  { name: "lastUpdate", label: "Ultimo aggiornamento" },
  { name: "status", label: "Stato" },
];

const getEmptyPracticeStatus = () =>
  Object.fromEntries(
    Object.entries(PRACTICE_STEP_OPTIONS).map(([macroStep, subStepArray]) => [
      macroStep,
      Object.fromEntries(
        subStepArray.map(({ value: subStepName }) => [
          subStepName,
          { status: false, startDate: moment(), lastUpdate: moment(), endDate: null, note: "" },
        ])
      ),
    ])
  );

const convertPracticesToSubmit = (practice, user = null) => {
  const steps = [];
  const startDates = [];
  let maxxLastUpdate = moment(0);
  Object.entries(practice).forEach(([stepName, subSteps]) => {
    if (stepName === "id") {
      return;
    }
    const newStep = { name: stepName, activities: [] };
    let note;
    const subStepStartDates = [];
    let maxLastUpdate = moment(0);
    Object.entries(subSteps).forEach(([subStepName, subStep]) => {
      if (subStepName === "note") {
        note = subStep;
        return;
      }
      subStepStartDates.push(subStep.startDate);
      const newSubStep = {
        name: subStepName,
        status: subStep.status,
        startDate: subStep.startDate.toISOString(),
        lastUpdate: subStep.lastUpdate.toISOString(),
        endDate: subStep?.status ? subStep.lastUpdate.toISOString() : null,
        note: subStep.note,
      };
      newStep.activities.push(newSubStep);
      if (maxLastUpdate.isBefore(subStep.lastUpdate)) {
        maxLastUpdate = subStep.lastUpdate;
      }
    });
    if (note) {
      newStep.note = note;
    }
    newStep.status = newStep.activities.every(({ status }) => !!status);
    const minStartDate = moment.min(subStepStartDates);
    newStep.startDate = minStartDate.toISOString();
    startDates.push(minStartDate);
    newStep.lastUpdate = maxLastUpdate.toISOString();
    newStep.endDate = newStep?.status ? newStep.lastUpdate : null;
    steps.push(newStep);
    if (maxxLastUpdate.isBefore(maxLastUpdate)) {
      maxxLastUpdate = maxLastUpdate;
    }
  });
  const startDate = moment.min(startDates).toISOString();
  const lastUpdate = maxxLastUpdate.toISOString();
  const status = steps.every(({ status: stat }) => !!stat);
  const endDate = status ? lastUpdate : null;
  const name = !user
    ? ""
    : `Pratica di ${user.name} ${user.lastName} del ${moment().format("DD/MM/YYYY")}`;
  return { name, status, lastUpdate, steps, startDate, endDate };
};

const convertPracticesToEdit = (practice) => {
  const { id } = practice;
  const result = { id };
  practice.steps.forEach((step) => {
    const subStepObj = {};
    step.activities.forEach((subStep) => {
      subStepObj[subStep.name] = {
        status: subStep.status,
        startDate: moment(subStep.startDate),
        lastUpdate: moment(subStep.lastUpdate),
        endDate: subStep?.endDate ? moment(subStep.endDate) : null,
        note: subStep.note,
      };
    });
    if (step.note) {
      subStepObj.note = step.note;
    }
    result[step.name] = subStepObj;
  });
  return result;
};

export default function StatusPractices({ userToView }) {
  const [controller] = useMaterialUIController();
  const [practiceStatus, setPracticeStatus] = useState(null);
  const [editingPractice, setEditingPractice] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const userId = userToView?.id;

  const onChangePracticeStatus = useCallback((v) => setPracticeStatus(v), [setPracticeStatus]);
  const handleDialogClose = useCallback(() => {
    setPracticeStatus(null);
    setEditingPractice(false);
  }, [setPracticeStatus, setEditingPractice]);

  const editPracticeAction = useCallback(
    (practice) => {
      setPracticeStatus(convertPracticesToEdit(practice));
      setEditingPractice(true);
    },
    [convertPracticesToEdit, setPracticeStatus, setEditingPractice]
  );

  const practicesGetter = useCallback(
    async (params) => {
      const response = await getPracticesByUserPaginate({ userId, ...params });
      return response.data;
    },
    [userId]
  );

  useEffect(() => setForceUpdate((v) => !v), [practicesGetter]);

  const onPracticeSubmit = useCallback(() => {
    setSubmitting(true);
    const api = editingPractice ? editPractice : addPractice;
    const id = editingPractice ? practiceStatus.id : userId;
    api(id, convertPracticesToSubmit(practiceStatus, userToView))
      .then((res) => {
        setForceUpdate((v) => !v);
        handleDialogClose();
        setAlertSuccess(true);
      })
      .catch((err) => {
        setAlertError(true);
      })
      .finally(() => setSubmitting(false));
  }, [setSubmitting, handleDialogClose, practiceStatus, editingPractice, userId, userToView]);

  return (
    <>
      <CustomList
        elemsGetter={practicesGetter}
        elemFields={PRACTICE_FIELDS}
        defaultSelectedKeys={PRACTICE_FIELDS.map((i) => i.name)}
        editPracticeAction={editPracticeAction}
        forceUpdate={forceUpdate}
        defaultSortBy="-updatedAt"
      />
      <Button
        variant="text"
        sx={{ float: "right" }}
        onClick={() => setPracticeStatus(getEmptyPracticeStatus())}
      >
        Aggiungi nuova pratica
      </Button>
      <Dialog
        open={!!practiceStatus}
        onClose={handleDialogClose}
        disableEscapeKeyDown
        fullWidth
        maxWidth="lg"
      >
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Stato pratica</DialogTitle>
          <IconButton onClick={handleDialogClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <DialogContent>
          <Practice value={practiceStatus} onChange={onChangePracticeStatus} />
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="contained"
            color="info"
            disabled={submitting}
            onClick={onPracticeSubmit}
          >
            Salva
          </MDButton>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: "#fff", zIndex: 99999 }} open={submitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {(alertSuccess || alertError) && (
        <SweetAlert
          style={{ display: "block" }}
          title={alertSuccess ? "Ok!" : "Ops!"}
          success={alertSuccess}
          error={alertError}
          onConfirm={() => {
            setAlertSuccess(false);
            setAlertError(false);
          }}
          confirmBtnText="Ok"
          confirmBtnStyle={{ boxShadow: "none", color: "blue" }}
        >
          {alertSuccess
            ? "La pratica è stata caricata con successo!"
            : "Si è verificato un errore nel caricamento della pratica. Se il problema persiste contatta il reparto tecnico"}
        </SweetAlert>
      )}
    </>
  );
}
