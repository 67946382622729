import axios from "axios";

const ENDPOINT = "https://api.opencagedata.com/geocode/v1/json";
const API_KEY = "f65747d9f27b4df791d224a530af38c6";

async function forward(city, address = null) {
  const res = await axios.get(ENDPOINT, {
    params: {
      key: API_KEY,
      language: "it",
      countrycode: "it",
      q: `${address || ""},+30,+${city}`,
    },
  });
  return res.data.results.map((value) => ({
    latitude: value.geometry.lat,
    longitude: value.geometry.lng,
    confidence: value.confidence.value,
    country: value.components.country,
    state: value.components.state,
    county: value.components.county,
    county_code: value.components.county_code,
    postcode: value.components.postcode,
    road: value.components.road,
    village: value.components.village,
    post_office: value.components.post_office,
    city: value.components.city,
    town: value.components.town,
    suburb: value.components.suburb,
  }));
}

async function reverse(latitude, longitude) {
  const res = await axios.get(ENDPOINT, {
    params: {
      key: API_KEY,
      language: "it",
      q: `${latitude}+${longitude}`,
    },
  });
  // console.log(res.data);
}

export { forward, reverse };
