/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "layouts/dashboards/customerProfile/ProfileInfoCard";
import Card from "@mui/material/Card";

import { useState, useEffect } from "react";

import { useMaterialUIController } from "context";
import { useParams, useNavigate } from "react-router-dom";
import getUser from "api/users/getUser";
import deleteFile from "api/file/deleteFile";
import CustomList from "components/custom/CustomList";
import DropzoneDialog from "components/custom/DropzoneDialog";
import escapeRegex from "utils/escapeRegex";
import updateFileLocation from "api/file/updateFileLocation";
import createMortgage from "api/file/createMortgage";
import downloadDoc from "utils/downloadDoc";
import StatusPractices from "components/custom/statusPractices";

const DEFAULT_SELECTED_KEYS = ["name", "date", "category", "city"];

function ProfileOverview() {
  const [controller, dispatch] = useMaterialUIController();
  const { userId } = useParams();
  const [userToView, setUserToView] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const navigate = useNavigate();
  const consultantViewHimself =
    controller?.user?.role === "consultant" && controller?.user?.id === userId;

  useEffect(() => {
    const utvId = userId === ":userId" ? controller.user.id : userId;
    if (
      utvId === controller.user.id ||
      ["consultant", "admin", "operator"].includes(controller.user.role)
    ) {
      getUser(userId === ":userId" ? controller.user.id : userId)
        .then((uu) => {
          if (controller.user.role === "consultant") {
            // check if customer is assigned to this consultant
            const consultant = typeof uu.consultant === "object" ? uu.consultant.id : uu.consultant;
            if (controller.user.id === uu.id || controller.user.id === consultant) {
              setUserToView(uu);
            } else {
              navigate(`/dashboards/profile/${controller.user.id}`);
            }
          } else {
            setUserToView(uu);
          }
        })
        .catch((err) => {
          // console.log(err);});
          navigate(`/dashboards/profile/${controller.user.id}`);
        });
    } else {
      navigate(`/dashboards/profile/${controller.user.id}`);
    }
  }, [userId, forceUpdate]);

  async function getDocList(params, section) {
    // let utv = userToView;
    // if (!utv || Object.keys(utv).length === 0) {
    //   utv = await getUser(userId === ":userId" ? controller.user.id : userId);
    //   setUserToView(utv);
    // }
    const utv = await getUser(userId === ":userId" ? controller.user.id : userId);
    setUserToView(utv);
    if (!utv.files || utv.files.length === 0) {
      return { results: [], totalPages: 0, totalResults: 0 };
    }
    const fields = ["name", "date", "section", "category", "city", "address", "streetNumber"];
    const validOpts = ["page", "limit", "sortBy"];
    const filters = Object.entries(params).filter(([key, value]) => fields.includes(key));
    const options = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => validOpts.includes(key))
    );
    const elems = utv.files
      .filter((value) => value.category === section)
      .map((value) => {
        const nameSplit = value.key.split("/");
        return {
          ...value,
          name: value.filename || (nameSplit ? nameSplit[nameSplit.length - 1] : "name"),
          date: new Date(value.timestamp).toLocaleDateString(),
          section: value.category,
          category: value.subCategory,
        };
      })
      .filter((doc) =>
        filters.every(([key, value]) => new RegExp(`.*${escapeRegex(value)}.*`, "i").test(doc[key]))
      );
    if (options.sortBy) {
      let sort = options.sortBy;
      let desc = false;
      if (options.sortBy.startsWith("-")) {
        sort = options.sortBy.slice(1);
        desc = true;
      }
      if (fields.includes(sort)) {
        if (desc) {
          elems.sort((a, b) => b[sort].localeCompare(a[sort]));
        } else {
          elems.sort((a, b) => a[sort].localeCompare(b[sort]));
        }
      }
    }
    if (!options.page || options.page < 1) {
      options.page = 1;
    }
    if (!options.limit || options.limit < 1) {
      options.limit = 1;
    }
    const start = (options.page - 1) * options.limit;
    const end = Math.min(start + options.limit, elems.length);
    const wow = {
      results: elems.slice(start, end),
      totalPages: Math.ceil(elems.length / options.limit),
      totalResults: elems.length,
    };
    return wow;
  }

  async function docMutuoGetter(params) {
    return getDocList(params, "mutuo");
  }

  async function docAnagraficaGetter(params) {
    return getDocList(params, "anagrafica");
  }

  async function docImmobileGetter(params) {
    return getDocList(params, "immobile");
  }

  function getDocFields() {
    return [
      { name: "name", label: "File" },
      { name: "date", label: "Data" },
      { name: "category", label: "Categoria" },
      { name: "city", label: "Città" },
      { name: "address", label: "Indirizzo" },
      { name: "streetNumber", label: "Civico" },
    ];
  }

  if (!userToView) {
    return <div>Loading user...</div>;
  }

  function deleteDoc(elem) {
    // console.log(elem);
    deleteFile(elem.key, userToView.id)
      .then(() => {
        // window.location.reload();
        setForceUpdate(!forceUpdate);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  async function modifyLocation(elem, location) {
    await updateFileLocation(
      elem.key,
      userId,
      Object.fromEntries(Object.entries(location).filter(([k, v]) => !!v))
    );
    // window.location.reload();
    setForceUpdate(!forceUpdate);
  }

  async function addMortgage(elem, mortgage) {
    const { amount, startDate, repetitionInterval } = mortgage;
    await createMortgage(elem.key, userId, amount, startDate, repetitionInterval);
    setForceUpdate(!forceUpdate);
  }

  const docsDropzoneVisible =
    controller?.user?.role === "admin" ||
    (controller?.user?.role === "user" && controller?.user?.id === userToView?.id) ||
    (controller?.user?.role === "consultant" &&
      (controller?.user?.id !== userId || controller?.user?.id !== userToView?.consultant?.id));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {["consultant", "admin", "operator"].includes(controller?.user?.role) && (
        <div style={{ display: "flex", justifyContent: "start", gap: "0.5rem" }}>
          <MDButton
            variant="contained"
            onClick={() => navigate(`/${controller.user.role}/userlist`)}
          >
            Vai alla lista pratiche
          </MDButton>
          {["consultant", "admin"].includes(controller?.user?.role) && (
            <MDButton
              variant="contained"
              onClick={() => navigate(`/${controller.user.role}/adduser`)}
            >
              Aggiungi cliente
            </MDButton>
          )}
        </div>
      )}
      <MDBox mt={5} mb={3}>
        <ProfileInfoCard
          title={
            userToView && userToView.name && userToView.lastName
              ? `${userToView.name} ${userToView.lastName}`
              : ""
          }
          shadow={false}
          user={{ ...userToView }}
          canEdit={
            controller.user.id === userToView?.id ||
            controller.user.role === "admin" ||
            (controller.user.role === "consultant" &&
              userToView &&
              controller.user.id === userToView?.consultant?.id)
          }
          onConfirmEdit={() => setForceUpdate(!forceUpdate)}
        />
      </MDBox>
      {!consultantViewHimself && (
        <Card sx={{ height: "100%", boxShadow: false, marginBottom: "1.5rem" }}>
          <MDBox pt={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h5" fontWeight="medium">
              Documenti
            </MDTypography>
          </MDBox>
          <MDBox p={2} sx={{ overflowX: "auto" }}>
            <Grid container spacing={6}>
              {controller.user.role !== "operator" && (
                <Grid item xs={12} md={12} xl={12}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Anagrafica
                  </MDTypography>
                  <MDBox>
                    {controller.user.role === "user" ||
                    (controller.user.role === "consultant" &&
                      (controller.user.id !== userId ||
                        controller.user.id !== userToView?.consultant?.id)) ? (
                      <CustomList
                        elemsGetter={docAnagraficaGetter}
                        elemFields={getDocFields()}
                        userRole={controller.user.role}
                        action={downloadDoc}
                        linkIcon="download"
                        dropdown
                        defaultSelectedKeys={DEFAULT_SELECTED_KEYS}
                        forceUpdate={forceUpdate}
                        key={userToView.id}
                      />
                    ) : (
                      <CustomList
                        elemsGetter={docAnagraficaGetter}
                        elemFields={getDocFields()}
                        userRole={controller.user.role}
                        action={downloadDoc}
                        linkIcon="download"
                        deleteAction={deleteDoc}
                        locationAction={modifyLocation}
                        dropdown
                        defaultSelectedKeys={DEFAULT_SELECTED_KEYS}
                        forceUpdate={forceUpdate}
                        key={userToView.id}
                      />
                    )}
                  </MDBox>
                  {docsDropzoneVisible && (
                    <MDBox style={{ display: "flex", justifyContent: "end" }}>
                      <DropzoneDialog
                        section="anagrafica"
                        userId={userToView.id}
                        selectOptions={[
                          { value: "Doc.ti riconoscimento", label: "Doc.ti riconoscimento" },
                          {
                            value: "Doc.ti resid./famiglia/matrimonio",
                            label: "Doc.ti resid./famiglia/matrimonio",
                          },
                          {
                            value: "Doc.ti attività professionale",
                            label: "Doc.ti attività professionale",
                          },
                        ]}
                        userLocation={userToView || {}}
                        onConfirmPopup={() => setForceUpdate(!forceUpdate)}
                      />
                    </MDBox>
                  )}
                </Grid>
              )}
              <Grid item xs={12} md={12} xl={12}>
                <MDTypography variant="h6" fontWeight="medium">
                  Immobile
                </MDTypography>
                <MDBox>
                  {controller.user.role === "user" ||
                  (controller.user.role === "consultant" &&
                    (controller.user.id !== userId ||
                      controller.user.id !== userToView?.consultant?.id)) ||
                  controller.user.role === "operator" ? (
                    <CustomList
                      elemsGetter={docImmobileGetter}
                      elemFields={getDocFields()}
                      userRole={controller.user.role}
                      action={downloadDoc}
                      linkIcon="download"
                      dropdown
                      defaultSelectedKeys={DEFAULT_SELECTED_KEYS}
                      forceUpdate={forceUpdate}
                      key={userToView.id}
                    />
                  ) : (
                    <CustomList
                      elemsGetter={docImmobileGetter}
                      elemFields={getDocFields()}
                      userRole={controller.user.role}
                      action={downloadDoc}
                      linkIcon="download"
                      deleteAction={deleteDoc}
                      locationAction={modifyLocation}
                      dropdown
                      defaultSelectedKeys={DEFAULT_SELECTED_KEYS}
                      forceUpdate={forceUpdate}
                      key={userToView.id}
                    />
                  )}
                </MDBox>
                {docsDropzoneVisible && (
                  <MDBox style={{ display: "flex", justifyContent: "end" }}>
                    <DropzoneDialog
                      section="immobile"
                      userId={userToView.id}
                      selectOptions={[
                        { value: "Atto di compravendita", label: "Atto di compravendita" },
                        { value: "Compromesso/preliminare", label: "Compromesso/preliminare" },
                        { value: "Perizia", label: "Perizia" },
                        { value: "Atto provenienza", label: "Atto provenienza" },
                        { value: "APE", label: "APE" },
                        { value: "Altri doc.ti immobile", label: "Altri doc.ti immobile" },
                      ]}
                      promptSecondHouse
                      userLocation={userToView || {}}
                      onConfirmPopup={() => setForceUpdate(!forceUpdate)}
                    />
                  </MDBox>
                )}
              </Grid>
              {controller.user.role !== "operator" && (
                <Grid item xs={12} md={12} xl={12}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Mutuo
                  </MDTypography>
                  <MDBox>
                    {controller.user.role === "user" ||
                    (controller.user.role === "consultant" &&
                      (controller.user.id !== userId ||
                        controller.user.id !== userToView?.consultant?.id)) ? (
                      <CustomList
                        elemsGetter={docMutuoGetter}
                        elemFields={getDocFields()}
                        userRole={controller.user.role}
                        action={downloadDoc}
                        linkIcon="download"
                        mortgageAction={addMortgage}
                        dropdown
                        defaultSelectedKeys={DEFAULT_SELECTED_KEYS}
                        forceUpdate={forceUpdate}
                        key={userToView.id}
                      />
                    ) : (
                      <CustomList
                        elemsGetter={docMutuoGetter}
                        elemFields={getDocFields()}
                        userRole={controller.user.role}
                        action={downloadDoc}
                        linkIcon="download"
                        deleteAction={deleteDoc}
                        locationAction={modifyLocation}
                        mortgageAction={addMortgage}
                        dropdown
                        defaultSelectedKeys={DEFAULT_SELECTED_KEYS}
                        forceUpdate={forceUpdate}
                        key={userToView.id}
                      />
                    )}
                  </MDBox>
                  {docsDropzoneVisible && (
                    <MDBox style={{ display: "flex", justifyContent: "end" }}>
                      <DropzoneDialog
                        section="mutuo"
                        userId={userToView.id}
                        selectOptions={[
                          { value: "Contratto di mutuo", label: "Contratto di mutuo" },
                          { value: "Domanda di mutuo", label: "Domanda di mutuo" },
                          { value: "Doc.ti reddituali", label: "Doc.ti reddituali" },
                          { value: "Surroga", label: "Surroga" },
                        ]}
                        promptSecondHouse
                        userLocation={userToView || {}}
                        onConfirmPopup={() => setForceUpdate(!forceUpdate)}
                      />
                    </MDBox>
                  )}
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Card>
      )}
      {!consultantViewHimself && ["admin", "consultant"].includes(controller?.user?.role) && (
        <Card sx={{ height: "100%", boxShadow: false }}>
          <MDBox pt={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h5" fontWeight="medium">
              Stato pratiche
            </MDTypography>
          </MDBox>
          <MDBox p={2} sx={{ overflowX: "auto" }}>
            <StatusPractices userToView={userToView} />
          </MDBox>
        </Card>
      )}
    </DashboardLayout>
  );
}

export default ProfileOverview;
