import { useCallback } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextareaAutosize from "@mui/material/TextareaAutosize";

export default function StepDetails({ value, onChange }) {
  const onChangeStatus = useCallback(
    (event) => {
      if (onChange) {
        onChange({ ...value, status: event.target.checked });
      }
    },
    [onChange, value]
  );
  const onChangeLastUpdate = useCallback(
    (lastUpdate) => {
      if (onChange) {
        onChange({ ...value, lastUpdate });
      }
    },
    [onChange, value]
  );
  const onChangeNote = useCallback(
    (event) => {
      if (onChange) {
        onChange({ ...value, note: event.target.value });
      }
    },
    [onChange, value]
  );

  if (!value) {
    return null;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: "1rem" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "0.75rem", lineHeight: "1.5", margin: 0, padding: 0 }}>
          Descrizione attività:
        </span>
        <TextareaAutosize
          placeholder="In questa attività si è provveduto a..."
          minRows={5}
          value={value.note}
          onChange={onChangeNote}
          style={{ padding: "0.5rem" }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1rem" }}>
        <FormControlLabel
          control={<Switch color="primary" checked={value.status} onChange={onChangeStatus} />}
          label="Attività completata"
          labelPlacement="end"
        />
        <DatePicker
          value={value.lastUpdate}
          label="Data ultimo aggiornamento"
          type="date"
          inputFormat="DD/MM/YYYY"
          onChange={onChangeLastUpdate}
          renderInput={(params) => <TextField {...params} />}
          readOnly
        />
      </div>
    </div>
  );
}
