import { Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function CustomSelect({
  control,
  name,
  label,
  type,
  validation = {},
  defaultValue = "",
  FormControlProps = {},
  SelectProps = {},
  options = [],
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={validation}
      render={({ field, fieldState, formState }) => (
        <FormControl
          {...FormControlProps}
          sx={{
            ...FormControlProps.sx,
            "& .MuiSelect-select.MuiInputBase-input": {
              padding: "0.75rem!important",
            },
          }}
        >
          <InputLabel>{label}</InputLabel>
          <Select {...field} label={label} {...SelectProps}>
            {options.map((value, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={`${value}_${index}`} value={value.value}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
