// @mui material components
// import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getAllUser from "api/users/getAllUser";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomList from "components/custom/CustomList";
import { useMaterialUIController } from "context";

const DEFAULT_SELECTED_KEYS = ["name", "lastName", "email", "city", "phone", "createdAt"];

function ParametricUserList({ userRole, practiceStatus = false }) {
  const [controller] = useMaterialUIController();
  const [consultantList, setConsultantList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllUser({ role: "consultant", limit: 100000 })
      .then((res) => {
        if (res.results && res.results.length > 0) {
          setConsultantList(res.results);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  function userGetter(props) {
    return new Promise((resolve, reject) => {
      // const newProps = { ...props, populate: "consultant", isNameAndLastNamePopulated: true };
      const newProps = { ...props, populate: "consultant" };
      if (controller.user.role === "consultant") {
        newProps.consultant = controller.user.id;
      }
      if (controller.user.role === "operator") {
        newProps.region = "lazio|toscana";
      }
      getAllUser(newProps)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  function getUserFields() {
    const filterByRole = (fields) => {
      if (controller.user.role === "operator") {
        return fields.filter(({ name }) => ["name", "lastName"].includes(name));
      }
      return fields;
    };
    if (userRole === "user") {
      const fields = [
        { name: "name", label: "Nome" },
        { name: "lastName", label: "Cognome" },
        { name: "email", label: "Email" },
        { name: "accountAppRisparmio", label: "App Risparmio" },
        { name: "phone", label: "Telefono" },
        { name: "fiscalCode", label: "Codice fiscale" },
        { name: "city", label: "Città" },
        { name: "address", label: "Indirizzo" },
        { name: "streetNumber", label: "Civico" },
      ];
      if (controller.user.role === "admin") {
        fields.push({ name: "consultant", label: "Consulente" });
      }
      fields.push({ name: "createdAt", label: "Creato" });
      fields.push({ name: "updatedAt", label: "Modificato" });
      return filterByRole(fields);
    }
    if (userRole === "consultant") {
      return filterByRole([
        { name: "name", label: "Nome" },
        { name: "lastName", label: "Cognome" },
        { name: "email", label: "Email" },
        { name: "phone", label: "Telefono" },
        // { name: "mobilePhone", label: "Cellulare" },
        // { name: "officePhone", label: "Telefono ufficio" },
        { name: "updatedAt", label: "Modificato" },
        { name: "createdAt", label: "Creato" },
      ]);
    }
    return filterByRole([
      { name: "name", label: "Nome" },
      { name: "lastName", label: "Cognome" },
      { name: "email", label: "Email" },
      { name: "phone", label: "Telefono" },
      { name: "createdAt", label: "Creato" },
      { name: "updatedAt", label: "Modificato" },
    ]);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <MDBox p={2} sx={{ overflowX: "auto" }}>
          <CustomList
            elemsGetter={userGetter}
            elemFields={getUserFields()}
            userRole={userRole}
            linkGetter={
              controller.user.role !== "operator"
                ? null
                : (user) => `/dashboards/profile/${user.id}`
            }
            showFiles
            consultantList={consultantList}
            dropdown
            defaultSelectedKeys={DEFAULT_SELECTED_KEYS}
            defaultSortBy="-createdAt"
            key={`parametric-${userRole}-list`}
            practiceStatus={controller.user.role === "operator" ? null : practiceStatus}
          />
        </MDBox>
        <div style={{ display: "flex", justifyContent: "end", margin: "0 2rem 2rem 0" }}>
          {userRole === "user" && ["consultant", "admin"].includes(controller?.user?.role) && (
            <MDButton
              variant="contained"
              color="info"
              onClick={() => navigate(`/${controller.user.role}/adduser`)}
            >
              Aggiungi cliente
            </MDButton>
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
}

export default ParametricUserList;
