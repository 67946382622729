import { isValidPassword } from "./ValidateFields";

export default function randomPassword() {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890_-.?!#@'$%&";
  const length = 8 + Math.floor(Math.random() * 9);
  let pass = "";
  // eslint-disable-next-line no-plusplus
  for (let x = 0; x < length; x++) {
    pass += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  if (isValidPassword(pass)) {
    return pass;
  }
  return randomPassword();
}
