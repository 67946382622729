import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl } = config;

/* *********************
  email
  password
********************** */

export default function forgotPassword(email) {
  // console.log("user", id, user);
  const endpoint = `${baseUrl}/v1/auth/forgot-password`;
  /* let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  } */
  const dataToSend = { email };
  // console.log("user dentro la chiamata", user);
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${baseUrl}`,
      "Access-Control-Allow-Credentials": true,
      // Authorization: `bearer ${access}`,
    },
    body: JSON.stringify(dataToSend),
  };
  // console.log('login endpoint', {endpoint, BODY });
  return new Promise((resolve, reject) => {
    fetch(endpoint, options)
      .then((response) => {
        if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
          reject();
        }
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}
