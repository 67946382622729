import config from "config/config";
import getTokens from "api/getTokens";
import axios from "axios";

const { baseUrl } = config;

export default function createMortgage(key, userId, amount, startDate, repetitionInterval) {
  const endpoint = `${baseUrl}/v1/file/create-mortgage`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const options = {
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": `${baseUrl}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
  };
  return axios.post(
    endpoint,
    { key, userId, recurringTransaction: { amount, userId, startDate, repetitionInterval } },
    options
  );
}
