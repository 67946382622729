import { useState, useEffect } from "react";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import MDButton from "components/MDButton";
import "rc-dropdown/assets/index.css";
import "rc-menu/assets/index.css";

export default function CustomDropdown({
  options = [],
  defaultSelectedKeys = [],
  selectedOnChange,
}) {
  const [visible, setVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys || []);

  useEffect(() => {
    selectedOnChange(selectedKeys);
  }, [selectedKeys]);

  const menu = (
    <Menu
      style={{ width: 150 }}
      multiple
      onSelect={(props) => {
        setSelectedKeys([...props.selectedKeys]);
      }}
      onDeselect={(props) => {
        setSelectedKeys([...props.selectedKeys]);
      }}
      selectedKeys={selectedKeys}
      defaultSelectedKeys={defaultSelectedKeys}
    >
      {options.map(({ name, label }) => (
        <MenuItem
          key={name}
          style={{ padding: "6px", fontWeight: 500, color: "black", textAlign: "center" }}
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
  return (
    <Dropdown
      trigger={["click"]}
      onVisibleChange={setVisible}
      visible={visible}
      closeOnSelect={false}
      overlay={menu}
      animation="slide-up"
    >
      <MDButton type="button" size="small" color="info" variant="text">
        nascondi colonne
      </MDButton>
    </Dropdown>
  );
}
