import React, { useState, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import Datetime from "react-datetime";
import htmlToDraft from "html-to-draftjs";
import uploadImage from "api/file/uploadImage";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import Grid from "@mui/material/Grid";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import config from "config/config";

const { imageRoot, baseUrl } = config;

const AddingPopup = ({ item, closePopup, saveElement }) => {
  const [visibleInApp, setVisibleInApp] = useState(
    item && item.visibleInApp ? item.visibleInApp : false
  );
  const [title, setTitle] = useState(item && item.title ? item.title : "");
  const [subtitle, setSubtitle] = useState(item && item.subtitle ? item.subtitle : "");
  const [imageUri, setImageUri] = useState(item && item.imageUri ? item.imageUri : "");
  const [abstract, setAbstract] = useState(item && item.abstract ? item.abstract : "");
  const [dateFrom, setDateFrom] = useState(item && item.dateFrom ? item.dateFrom : null);
  const [dateTo, setDateTo] = useState(item && item.dateTo ? item.dateTo : null);
  const [status, setStatus] = useState(item && item.status ? item.status : "published");
  const [htmlContent, setHtmlContent] = useState(item && item.htmlContent ? item.htmlContent : "");
  const [error, setError] = useState("");
  let initialContent = EditorState.createEmpty();
  if (item && item.htmlContent) {
    const blocksFromHTML = convertFromHTML(item.htmlContent.toString());
    if (blocksFromHTML && blocksFromHTML.contentBlocks) {
      const contentStateFromHTML = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      initialContent = EditorState.createWithContent(contentStateFromHTML);
    }
  }
  const [editorState, setEditorState] = useState(initialContent);

  const editor = useRef(null);

  function getInput(type, editFunction, defaultValue) {
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        className={error === type ? "field_with_error" : ""}
        value={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "30px" }}
      />
    );
  }

  function getSwitch(value, changeFunction, label) {
    return (
      <div
        className="switch_container"
        style={{
          position: "relative",
          marginRight: "10px",
          marginBottom: "30px",
          textAlign: "left",
        }}
      >
        {label}
        <Switch
          checked={value}
          onChange={() => {
            // CHANGE
            changeFunction(!value);
          }}
          sx={{
            width: 300,
            color: "success.main",
          }}
          color="warning"
          // disabled={this.state.isActive}
          value="onlyOwner"
        />
      </div>
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        className="selectDefault"
        value={defaultValue}
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginBottom: "30px",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`status-${index.toString()}`}>
            {el}
          </option>
        ))}
      </select>
    );
  }

  function getCalendar(value, changeFunction, label) {
    return (
      <div
        className={error === label ? "field_with_error" : ""}
        style={{ position: "relative", marginRight: "10px", marginBottom: "30px" }}
      >
        <Datetime
          inputProps={{
            placeholder: label,
            value: value
              ? `${moment(value).format("DD")}-${moment(value).format("MM")}-${moment(value).format(
                  "YYYY"
                )}`
              : "",
          }}
          closeOnSelect
          timeFormat={false}
          value={
            value
              ? `${moment(value).format("DD")}-${moment(value).format("MM")}-${moment(value).format(
                  "YYYY"
                )}`
              : ""
          }
          onChange={(e) => {
            changeFunction(moment(e).format("L"));
          }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  return (
    <SweetAlert
      style={{ display: "block" }}
      title="Aggiungi articolo"
      onCancel={() => {
        closePopup(false);
      }}
      onConfirm={() => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        if (!title) {
          setError("Titolo");
        } else if (!subtitle) {
          setError("Sottotitolo");
        } else if (!abstract) {
          setError("Abstract");
        } else if (!markup || !editorState.getCurrentContent().getPlainText("\u0001")) {
          setError("Description");
        } else if (!imageUri) {
          setError("ImageUri");
        } else {
          const elementToSend = {
            title,
            subtitle,
            abstract,
            imageUri,
            status,
            dateFrom: dateFrom || new Date(),
            dateTo: dateTo || new Date("2123-01-01T01:00:00"),
            htmlContent: markup,
            visibleInApp,
            date: item && item.date ? item.date : new Date(),
          };
          saveElement(elementToSend);
          setError("");
        }
      }}
      cancelBtnText="Annulla"
      showCancel
      closeOnClickOutside={false}
      confirmBtnText="Salva"
      confirmBtnStyle={{ boxShadow: "none", color: "blue", marginLeft: "10px" }}
      cancelBtnStyle={{ boxShadow: "none", color: "red", marginLeft: "10px" }}
    >
      <Grid item xs={12}>
        {getInput("Titolo", setTitle, title)}
      </Grid>
      <Grid item xs={12}>
        {getInput("Sottotitolo", setSubtitle, subtitle)}
      </Grid>
      <Grid item xs={12}>
        {getInput("Abstract", setAbstract, abstract)}
      </Grid>
      <Grid item xs={12}>
        {getSwitch(visibleInApp, setVisibleInApp, "Visibile in app")}
      </Grid>
      <Grid item xs={12}>
        {getSelect(["published", "unpublished", "trashed", "archived"], setStatus, status)}
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        {getCalendar(dateFrom, setDateFrom, "dateFrom")}
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        {getCalendar(dateTo, setDateTo, "dateTo")}
      </Grid>
      <Grid item xs={12} className={error === "Description" ? "field_with_error" : ""}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={(es) => {
            setEditorState(es);
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "20px", marginTop: "30px", textAlign: "left" }}>
        <span className={error === "ImageUri" ? "error_message" : ""} style={{ fontSize: "18px" }}>
          Immagine:
        </span>{" "}
        <br />
        {imageUri ? (
          <div style={{ position: "relative" }}>
            <IconButton
              onClick={() => {
                setImageUri("");
              }}
              size="small"
              style={{
                position: "absolute",
                left: "5px",
                bottom: "5px",
                color: "#fff",
                zIndex: "2",
              }}
              disableRipple
            >
              <Icon fontSize="medium">delete</Icon>
            </IconButton>
            <img className="image_for_article" alt="" src={imageRoot + imageUri} />
          </div>
        ) : (
          <MDDropzone
            options={{
              addRemoveLinks: true,
              maxFilesize: 1000000,
              // eslint-disable-next-line
              url: (file) => {
                return `${baseUrl}/v1/ping/pong`;
              },
              maxFiles: 1,
              success: (file) => {
                if (file && file.dataURL) {
                  // TODO gestire il multi caricamento contemporaneo o toglierlo
                  uploadImage(file).then((res) => {
                    if (res && res.file) {
                      setImageUri(res.file);
                    }
                    return null;
                  });
                }
                return true;
              },
              // removedfile: (file) => {
              //   console.log("ho cancellato", file);
              //   setImageUri("");
              //   if (file.previewElement != null && file.previewElement.parentNode != null) {
              //     file.previewElement.parentNode.removeChild(file.previewElement);
              //   }
              //   return true;
              //   // eslint-disable-next-line
              //   // return this._updateMaxFilesReachedClass();
              // },
            }}
          />
        )}
      </Grid>
      {error ? (
        <Grid container spacing={2} sx={{ textAlign: "center", marginTop: "30px", width: "100%" }}>
          <div className="error_message">
            Sembra che tu non abbia inserito tutti i campi o alcuni di questi siano errati
          </div>
        </Grid>
      ) : null}
    </SweetAlert>
  );
};

export default AddingPopup;
