import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { NumericFormat } from "react-number-format";

// function customInput(inputProps) {
//   return <TextField {...inputProps} label="Importo *" />;
// }

export default function Amount({
  control,
  name,
  label,
  type,
  validation = {},
  defaultValue = 0,
  TextFieldProps = {},
}) {
  function customInput(inputProps) {
    return <TextField {...inputProps} label={label} {...TextFieldProps} />;
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={validation}
      render={({ field: { ref, ...field }, fieldState, formState }) => (
        <NumericFormat
          {...field}
          customInput={customInput}
          label={label}
          thousandSeparator="."
          decimalSeparator=","
          prefix="€ "
          allowNegative={false}
          onChange={(v) => {
            // console.log(v);
          }}
          onValueChange={(v) => {
            field.onChange(Number(v.value));
          }}
          variant="outlined"
          defaultValue={defaultValue}
          error={!!formState.errors[name]}
          helperText={formState.errors[name] && formState.errors[name].message}
        />
      )}
    />
  );
}
