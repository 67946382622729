/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout/custom";

// Images
import bgImage from "assets/images/illustrations/illustration-reset.png";

import forgotPassword from "api/auth/forgotPassword";

function ForgotPasswordCustom() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const performForgotPassword = () => {
    forgotPassword(email)
      .then((resp) => {
        setError(false);
        setSuccess(true);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      })
      .catch((err) => {
        setError(true);
        setSuccess(false);
      });
  };

  return (
    <IllustrationLayout illustration={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Area riservata
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1} p="0 20px">
            Riceverai una email con un link per reimpostare la tua password entro 60 secondi.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performForgotPassword(email);
                  }
                }}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={(e) => {
                  performForgotPassword(email);
                }}
              >
                reset
              </MDButton>
            </MDBox>
            {error ? (
              <MDBox mt={4} mb={1}>
                <MDTypography display="block" variant="button" my={1} p="0 20px">
                  Ops, qualcosa è andato storto! Controlla i dati immessi o riprova fra qualche
                  minuto
                </MDTypography>
              </MDBox>
            ) : null}
            {success ? (
              <MDBox mt={4} mb={1}>
                <MDTypography display="block" variant="button" my={1} p="0 20px">
                  Controlla la tua email!
                </MDTypography>
                <MDTypography display="block" variant="button" my={1} p="0 20px">
                  Stai per essere reindirizzato alla pagina di login.
                </MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </IllustrationLayout>
  );
}

export default ForgotPasswordCustom;
