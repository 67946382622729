import { NoEncryption } from "@mui/icons-material";

export function isFilled(value) {
  return value && value.length > 0;
}

export function isEmail(value) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
}

export function isPhoneNumber(value) {
  return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(value);
}

export function isNormalNumber(value) {
  return /^[0-9]+$/.test(value);
}

export function isFiscalCode(value) {
  return value.length === 16;
}

export function isValidPassword(value) {
  return value.length >= 8 && value.match(/\d/) && value.match(/[a-zA-Z]/);
}

export function checkCustomerFields(user) {
  if (!user.name || !isFilled(user.name)) {
    return "Inserisci il nome";
  }
  if (!user.lastName || !isFilled(user.lastName)) {
    return "Inserisci il cognome";
  }
  if (!user.email || !isEmail(user.email)) {
    return "Inserisci un indirizzo email valido";
  }
  if ("password" in user && (!user.password || !isValidPassword(user.password))) {
    return "Inserisci una password";
  }
  // if (!user.phone || !isPhoneNumber(user.phone)) {
  //   return "Inserisci un numero di telefono valido";
  // }
  // if (!user.fiscalCode || !isFiscalCode(user.fiscalCode)) {
  //   return "Inserisci un codice fiscale";
  // }
  if (!user.city || !isFilled(user.city)) {
    return "Inserisci una città";
  }
  if (!user.address || !isFilled(user.address)) {
    return "Inserisci un indirizzo";
  }
  // if (!user.consultant || !isFilled(user.consultant)) {
  //   return "Seleziona un consulente";
  // }
  // if (!user.accountAppRisparmio || !isFilled(user.accountAppRisparmio)) {
  //   return "Inserisci un account App Risparmio";
  // }
  return null;
}

export function checkConsultantFields(user) {
  if (!user.name || !isFilled(user.name)) {
    return "Inserisci il nome";
  }
  if (!user.lastName || !isFilled(user.lastName)) {
    return "Inserisci il cognome";
  }
  if (!user.email || !isEmail(user.email)) {
    return "Inserisci un indirizzo email valido";
  }
  if ("password" in user && (!user.password || !isValidPassword(user.password))) {
    return "Inserisci una password";
  }
  // if (!user.phone || !isPhoneNumber(user.phone)) {
  //   return "Inserisci un numero di telefono valido";
  // }
  // if (!user.mobilePhone || !isPhoneNumber(user.mobilePhone)) {
  //   return "Seleziona un numero di cellulare valido";
  // }
  // if (!user.officePhone || !isPhoneNumber(user.officePhone)) {
  //   return "Seleziona un numero di telefono dell'ufficio";
  // }
  return null;
}

export function checkUserFields(user) {
  if (!user.name || !isFilled(user.name)) {
    return "Inserisci il nome";
  }
  if (!user.lastName || !isFilled(user.lastName)) {
    return "Inserisci il cognome";
  }
  if (!user.email || !isEmail(user.email)) {
    return "Inserisci un indirizzo email valido";
  }
  if ("password" in user && (!user.password || !isValidPassword(user.password))) {
    return "Inserisci una password";
  }
  // if (!user.phone || !isPhoneNumber(user.phone)) {
  //   return "Inserisci un numero di telefono valido";
  // }
  return null;
}
